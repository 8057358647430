
import Login from "./pages/login/Login";
import DashboardMenu from "./pages/dashboard/DashboardMenu";
import "./style/dark.scss";
import "./App.css"
import { PrivateRoute } from "./redux/helpers/PrivateRoute";
import {
  BrowserRouter,
  Routes,
  Route,

} from "react-router-dom";
import ForgotPassword from "./pages/forgot-password/ForgotPassword";
import ResetPassword from "./pages/reset-password/ResetPassword";
import Profile from "./pages/profile/Profile";
import EditProfile from "./pages/profile/ProfileEdit";
import Setting from "./pages/setting/Setting";
import Notification from "./pages/notification/Notification";
import Messages from "./pages/message/Message";
import AllManageUser from "./pages/manage-user/AllUser";
import AddManageUser from "./pages/manage-user/AddUser";
import AllRoles from "./pages/manage-roles/AllRoles";
import AddRole from "./pages/manage-roles/AddRole";
import ResourcesList from "./pages/resources/ResoucesList";
import ResourceView from "./pages/resources/ResourceView";
import ResourceEdit from "./pages/resources/ResourceEdit";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import LocalStorageDataService from "./components/common-function/GetLocalstorage";
import { getAsigneRoleMenuList } from "./redux/features/masterSlice";
import Technology from "./pages/master/Technology";
import QuestionLevel from "./pages/master/QuestionLevel";
import QuestionType from "./pages/master/QuestionType";
import Stepform from "./pages/attemp-test/Stepform";
import Stepformnew from "./pages/attemp-test/Stepformnew";
import CandidateList from "./pages/candidate/CandidateList";
import CandidateExamSchdule from "./pages/CandidateExamSchdule";
import CandidateEdit from "./pages/candidate/CandidateEdit";
import CandidateView from "./pages/candidate/CandidateView";
import QuestionSet from "./pages/master/QuestionSet";
import Question from "./pages/master/Question";
import Error404 from "./pages/error-pages/Error404";
import TermandCondition from "./pages/term-and-condition/TermandCondition";
import CandidateKyc from "./pages/candidate-kyc/CandidateKyc";
import TestCandidateForm from "./pages/attemp-test/TestCandidateForm";
import Industry from "./pages/master/Industry";

import Batch from "./pages/master/Batch";
import Qualification from "./pages/master/Qualification";
import Register from "./pages/Register/Register";
import Verify from "./pages/Register/Verify";
import ScheduleForm from "./pages/master/ScheduleForm";
import CandidateExamList from "./pages/candidate-exam-list/CandidateExamList";
import "react-datepicker/dist/react-datepicker.css";
import ScreenCaptureTest from "./pages/candidate-kyc/ScreenCaptureTest";
import CandidateKyctest from "./pages/candidate-kyc/Candidatekyctest";
import AddDepartment from "./pages/master/AddDepartment";
import Departments from "./pages/master/Departments";

import PendingEvaluationExamList from "./pages/evaluation-candidate/EvaluationExamList";
import QusetionDetailsCandidateExam from "./pages/evaluation-candidate/QuestionDetailsCandidateExam";
import RelatedSkills from "./pages/master/RelatedSkills";
import IndustryDepartment from "./pages/master/IndustryDepartment";
import IndustryDepSpecialization from "./pages/master/IndustryDepartmentSpecialisation";
import IndustryDepTechSkillmapping from "./pages/master/IndustryDepTechSkillmapping";
import CandidateExamResult from "./pages/candidate-exam-process/CandidateExamResult";

const user = LocalStorageDataService.userDetail();
const tmsRole = user && user.tms_role_id
const roleWiseFilter = tmsRole && `{"filter":{"status":{"value":"True","operation": "eq"},"role_id":{"value":${tmsRole},"operation": "eq"}},"no_limit":True}`

const { REACT_APP_ENV } = process.env;

function App() {

  // Acl Router
  const dispatch = useDispatch()
  useEffect(() => {
    const auth = !!localStorage.getItem(`${REACT_APP_ENV}_CES`);
    auth && dispatch(getAsigneRoleMenuList(roleWiseFilter))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<Error404 />} />

        <Route exact path="/" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:resetToken" element={<ResetPassword />} />
        <Route path="/public/candidate-registration" element={<Register />} />
        <Route path="/public/candidate-registration/verify" element={<Verify />} />

        <Route
          exact path="/dashboard"
          element={
            <PrivateRoute path="dashboard?reload=done">
              <DashboardMenu />
            </PrivateRoute>
          }
        />


        <Route path="/profile" element={<PrivateRoute path="profile"><Profile /></PrivateRoute>} />
        <Route path="/profile/:Id" element={<PrivateRoute path="profile"><Profile /></PrivateRoute>} />
        <Route path="/edit-profile" element={<PrivateRoute path="edit-profile"><EditProfile title="Edit Profile" /></PrivateRoute>} />
        <Route path="/setting" element={<Setting />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/all-massages" element={<Messages />} />
        <Route path="/test" element={<Stepform />} />
        <Route path="/testnew" element={<Stepformnew />} />

        {/* User Type */}

        <Route path="/all-user" element={
          <PrivateRoute path="all-user"><AllManageUser /></PrivateRoute>
        } />

        <Route path="/add-new-user" element={
          <PrivateRoute path="add-new-user"><AddManageUser title="Add New User-type" /></PrivateRoute>
        } />
        <Route path="/edit-user/:id" element={
          <PrivateRoute path="edit-user">
            <AddManageUser title="Edit User-type " />
          </PrivateRoute>
        } />


        {/* roles */}
        <Route path="/roles" element={<PrivateRoute path="roles"><AllRoles /></PrivateRoute>} />
        <Route path="/add-new-role" element={
          <PrivateRoute path="add-new-role">
            <AddRole title="Add New Role" />
          </PrivateRoute>
        } />
        <Route path="/edit-role/:id" element={
          <PrivateRoute path="edit-role">
            <AddRole title="Edit Role" />
          </PrivateRoute>
        } />

        {/* Users  Route */}
        <Route path="/users">
          <Route index element={<PrivateRoute path="users"><ResourcesList /></PrivateRoute>} />
          <Route path="edit/:resourceid" element={<PrivateRoute path="users/edit"><ResourceEdit /></PrivateRoute>} />
          <Route path="add" element={<PrivateRoute path="users/add"><ResourceEdit /></PrivateRoute>} />
          <Route path="view/:resourceid" element={<PrivateRoute path="users/view"><ResourceView /></PrivateRoute>} />
        </Route>

        {/* Users  Route */}
        <Route path="/candidate">
          <Route index element={<PrivateRoute path="candidate"><CandidateList /></PrivateRoute>} />
          <Route path="edit/:resourceid" element={<PrivateRoute path="candidate/edit"><CandidateEdit /></PrivateRoute>} />
          <Route path="add" element={<PrivateRoute path="candidate/add"><EditProfile title="Add Profile" /></PrivateRoute>} />
          <Route path="view/:resourceid" element={<PrivateRoute path="candidate/view"><CandidateView /></PrivateRoute>} />
        </Route>

        {/* all master routing */}
        <Route path="/technology" element={<PrivateRoute path="technology"><Technology /></PrivateRoute>} />
        <Route path="/question-level" element={<PrivateRoute path="question-level"><QuestionLevel /></PrivateRoute>} />
        <Route path="/question-type" element={<PrivateRoute path="question-type"><QuestionType /></PrivateRoute>} />
        <Route path="/batch" element={<PrivateRoute path="batch"><Batch /></PrivateRoute>} />
        <Route path="/sets" element={<PrivateRoute path="set"><QuestionSet /></PrivateRoute>} />
        <Route path="/questions" element={<PrivateRoute path="question"><Question /></PrivateRoute>} />
        <Route path="/qualification" element={<PrivateRoute path="qualification"><Qualification /></PrivateRoute>} />
        <Route path="/industry" element={<PrivateRoute path="industry"><Industry /></PrivateRoute>} />

        <Route path="/candidate_exam_schedule" element={<PrivateRoute path="candidate_exam_schedule"><CandidateExamSchdule /></PrivateRoute>} />
        <Route path="/term-and-condition" element={<PrivateRoute path="term-and-condition"><TermandCondition /></PrivateRoute>} />
        <Route path="/candidate-kyc" element={<PrivateRoute path="candidate-kyc"><CandidateKyc /></PrivateRoute>} />
        {/* <Route path="/my-test" element={<PrivateRoute path="my-test"><ExamScheduledList /></PrivateRoute>} /> */}
        <Route path="/candidate-test" element={<PrivateRoute path="my-test"><TestCandidateForm /></PrivateRoute>} />
        <Route path="/master-schedule" element={<PrivateRoute path="master-schedule"><ScheduleForm /></PrivateRoute>} />

        <Route path="/my-test" element={<PrivateRoute path="my-test"><CandidateExamList /></PrivateRoute>} />

        <Route path="/screen-capture-test" element={ <PrivateRoute path="screen-capture-test"> <ScreenCaptureTest/></PrivateRoute>}/> 
        <Route path="/Candidate-Kyc-test" element={ <PrivateRoute path="Candidate-Kyc-test"> <CandidateKyctest/> </PrivateRoute> }/> 
        <Route path="/all-depratment" element={<PrivateRoute path="all-depratment"><Departments /></PrivateRoute>} />
        <Route path="/add-new-department" element={
          <PrivateRoute path="add-new-department">
            <AddDepartment inputs={Departments} title="Add New Department" />
          </PrivateRoute>
        } />
        <Route path="/edit-department/:id" element={
          <PrivateRoute path="edit-department">
            <AddDepartment title="Edit Department" inputs={Departments} />
          </PrivateRoute>
        } />

        {/* evailation  */}
        <Route path="/evaluation-exam-list" element={<PrivateRoute path="evaluation-exam-list"><PendingEvaluationExamList /></PrivateRoute>} />
        <Route path="/view-question-details/:id/:technology_id" element={<PrivateRoute path="view-question-details"><QusetionDetailsCandidateExam /></PrivateRoute>} />
        <Route path="/related-skills" element={<PrivateRoute path="related-skills"><RelatedSkills /></PrivateRoute>} />
        <Route path="/industry-department" element={<PrivateRoute path="industry-department"><IndustryDepartment /></PrivateRoute>} />
        <Route path="/industry-dep-specialization" element={<PrivateRoute path="industry-dep-specialization"><IndustryDepSpecialization /></PrivateRoute>} />
        <Route path="/industry-dep-tech-skill" element={<PrivateRoute path="industry-dep-tech-skill"><IndustryDepTechSkillmapping /></PrivateRoute>} />
        {/* IndustryDepTechSkillmapping */}

        {/* //exam result view */}
        <Route path="/candidate-result-view/:id" element={<PrivateRoute path="candidate-result-view"><CandidateExamResult /></PrivateRoute>} />


      </Routes>

    </BrowserRouter>
  );
}

export default App;
