import "./sidebar.scss"
import DashboardIcon from '@mui/icons-material/Dashboard';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import FolderIcon from '@mui/icons-material/Folder';
import ContactsIcon from '@mui/icons-material/Contacts';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import EngineeringIcon from '@mui/icons-material/Engineering';
import AssignmentIcon from '@mui/icons-material/Assignment';
import MenuBookIcon from '@mui/icons-material/MenuBook';

import { Link, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from '../../redux/features/authSlice'
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import LocalStorageDataService from '../common-function/GetLocalstorage';
import Logo from '../../images/fevicon.png'
import { getMenuListForSideBar } from "../../redux/features/sidebarSlice";
import LogoutModal from "../common-function/LogoutModal";

let user = LocalStorageDataService.userDetail();
let tmsRole = user?.tms_role
const tmsRoleId = user?.tms_role_id
const roleWiseFilter = tmsRole && `{"filter":{"status":{"value":"True","operation": "eq"},"role_id":{"value":${tmsRoleId},"operation": "eq"}},"no_limit":True}`

const Sidebar = () => {
  const [urlPath, setUrlPath] = useState();
  const [parentTitle, setParentTitle] = useState();
  let siderData = []
  // eslint-disable-next-line
  {
    tmsRole.role_key === 'candidate' ?
      siderData = [
        {
          icon: 'DashboardIcon',
          menu: 'Dashboard',
          link: '/dashboard',
          status: 'active',
          submenus: [],
          menuItem: [{
            icon: 'AccountCircleIcon',
            menu: 'Profile',
            link: '/profile',
            status: 'active',
          },
          {
            icon: 'AssignmentIcon',
            menu: 'My Test',
            link: '/my-test',
            status: 'active',
            submenus: []
          },
          {
            icon: 'LogoutIcon',
            menu: 'Logout',
            link: '/',
            status: 'active',
          }]

        }]
      :
      tmsRole.role_key === 'evaluator' ?
        siderData = [
          {
            icon: 'DashboardIcon',
            menu: 'Dashboard',
            link: '/dashboard',
            status: 'active',
            submenu: [
              {
                icon: 'MenuBookIcon',
                title: 'Evaluator',
                status: 'active',
                submenus: [
                  {
                    menu: 'Evaluation Exam List',
                    link: '/evaluation-exam-list',
                    icon: '',
                    status: 'active',
                  },
                  // {
                  //   menu: 'Evaluated Exam',
                  //   link: '/evaluated-exam',
                  //   icon: '',
                  //   status: 'active',
                  // },
                  // {
                  //   menu: 'Evaluated Result',
                  //   link: '/evaluated-exam-result',
                  //   icon: '',
                  //   status: 'active',
                  // },

                ]
              },
            ],
            menuItem: [{
              icon: 'AccountCircleIcon',
              menu: 'Profile',
              link: '/profile',
              status: 'active',
              submenus: []
            },
            {
              icon: 'LogoutIcon',
              menu: 'Logout',
              link: '/',
              status: 'active',
            }]
          }]
        :
        siderData = [
          {
            icon: 'DashboardIcon',
            menu: 'Dashboard',
            link: '/dashboard',
            status: 'active',
            submenu: [
              {
                icon: 'SupervisedUserCircleIcon',
                title: 'Master',
                status: 'active',
                submenus: [
                  {
                    menu: 'Technology',
                    link: '/technology',
                    icon: '',
                    status: 'active',
                  },
                  {
                    menu: 'Experience Level',
                    link: '/question-level',
                    status: 'active',
                  },
                  {
                    menu: 'Question Type',
                    link: '/question-type',
                    status: 'active',
                  },
                  {
                    menu: 'Sets',
                    link: '/sets',
                    status: 'active',
                  },
                  {
                    menu: 'Batch',
                    link: '/batch',
                    status: 'active',
                  },
                  {
                    menu: 'Questions',
                    link: '/questions',
                    status: 'active',
                  },
                  {
                    menu: 'Qualification',
                    link: '/qualification',
                    status: 'active',
                  },
                  {
                    menu: 'Schedule',
                    link: '/master-schedule',
                    status: 'active',
                  },
                  {
                    menu: 'Industry',
                    link: '/industry',
                    status: 'active',
                  },
                  {
                    menu: 'Related Skills',
                    link: '/related-skills',
                    status: 'active',
                  },
                  {
                    menu: 'Departments',
                    link: '/all-depratment',
                    status: 'active',
                  },
                  {
                    menu: 'Industry Department',
                    link: '/industry-department',
                    status: 'active',
                  },
                  {
                    menu: 'industry Department Technology',
                    link: '/industry-dep-specialization',
                    status: 'active',
                  },
                  {

                    menu: 'Industry Department Technology Skill',
                    link: '/industry-dep-tech-skill',
                    status: 'active',
                  },
                ]
              },
              {
                icon: 'ControlCameraIcon',
                title: 'Manage Roles',
                status: 'active',
                submenus: [
                  {
                    menu: 'All Roles',
                    link: '/roles',
                    icon: '',
                    status: 'active',
                  },
                  {
                    menu: 'Add New Role',
                    link: '/add-new-role',
                    icon: '',
                    status: 'active',
                  }
                ]
              }],
            menuItem: [{
              icon: 'AccountCircleIcon',
              menu: 'Profile',
              link: '/profile',
              status: 'active',
            },
            {
              icon: 'AccountCircleIcon',
              menu: 'Candidate Test',
              link: '/candidate_exam_schedule',
              status: 'active',
            },
            {

              icon: 'ContactsIcon',
              menu: 'Users',
              link: '/users',
              status: 'active',
            },
            {
              icon: 'ContactsIcon',
              menu: 'Candidate',
              link: '/candidate',
              status: 'active',
            },
            {
              icon: 'LogoutIcon',
              menu: 'Logout',
              link: '',
              status: 'active',
            }]
          }]
  }

  const { logoutUser, authLoggedIn } = useSelector((state) => state.authUser)
  const [showLogout, setShowLogout] = useState(false)

  const dispatch = useDispatch();
  const navigate = useNavigate()

  // const logout_user = useCallback(() => {
  //   setShowLogout
  //   dispatch(logout());
  // }, [dispatch]);

  const logout_user = () => {
    setShowLogout(true)
  }

  const handleClose = () => {
    setShowLogout(false)
  }

  const handleClickOpen = () => {
    dispatch(logout());
  }

  if (authLoggedIn === false && logoutUser && logoutUser.message) {
    navigate('/')
  }

  const { data } = useSelector((state) => state.navbartoggle);
  const fetchUrlPath = () => {
    const menuData = siderData[0];
    const path = window.location.href;
    let pathArr = path.split('/');
    pathArr = pathArr.splice(3);
    const pathName = '/' + pathArr.join('/');
    setUrlPath(pathName);
    let pathObject;

    if (menuData.link === pathName) pathObject = menuData;

    if (pathObject)
      pathObject = menuData.menuItem.filter((item) => item.link === pathName);

    if (!pathObject || (Array.isArray(pathObject) && pathObject.length === 0)) {
      if (menuData?.submenu) {
        for (let i = 0; i < menuData.submenu.length; i++) {
          const item = menuData.submenu[i];
          if (item?.submenus?.length > 0) {
            pathObject = item.submenus.filter((submenuItem) => submenuItem.link === pathName);
            if (pathObject && Array.isArray(pathObject) && pathObject.length > 0) {
              setParentTitle(item.title);
              break;
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    fetchUrlPath()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(getMenuListForSideBar(roleWiseFilter))
  }, [dispatch])

  const subItemsMapping = (item, index) => {
    return <MenuItem key={index} active={urlPath === item.link} >
      {item.menu}
      {item.link && <Link to={item.link}></Link>}
    </MenuItem>
  }

  return (

    // dynamic side bar menu code using api 
    <div className={data === true ? 'sidebar-collapse sidebar' : 'sidebar'} >
      <div className="top">
        <Link to="/dashboard" style={{ textDecoration: "none" }}>
          <span className="logo"><img src={Logo} alt={Logo} /><span className="logo-text">CES</span></span>
        </Link>
      </div>

      <ProSidebar>
        {siderData.map((item, index) => {
          return (
            <Menu key={index} >
              <MenuItem id={item.link} active={urlPath === item.link} icon={<DashboardIcon />}>
                {item.menu}
                <Link to={item.link} />
              </MenuItem>
              {item.submenu?.map((subItem, subIndex) => {
                return (

                  <SubMenu key={index} title={subItem.title}
                    onClick={() => {
                      if (parentTitle === subItem.title)
                        return setParentTitle('')
                      setParentTitle(subItem.title)
                    }}
                    open={subItem.title === parentTitle}
                    icon={
                      subItem.icon === "SupervisedUserCircleIcon" ? <SupervisedUserCircleIcon /> :
                        subItem.icon === "ControlCameraIcon" ? <ControlCameraIcon /> :
                          subItem.icon === "FolderIcon" ? <FolderIcon /> :
                            subItem.icon === "ContactsIcon" ? <ContactsIcon /> :
                              subItem.icon === "CardMembershipIcon" ? <CardMembershipIcon /> :
                                subItem.icon === "AutorenewIcon" ? <AutorenewIcon /> :
                                  subItem.icon === "CalendarMonthIcon" ? <CalendarMonthIcon /> :
                                    subItem.icon === "MenuBookIcon" ? <MenuBookIcon /> :

                                      <PictureAsPdfIcon />
                    }>
                    {
                      subItem.icon === 'SupervisedUserCircleIcon' &&
                      subItem.submenus?.map((subItems, subIndexs) => {
                        return subItemsMapping(subItems, subIndexs)
                      })
                    }
                    {subItem.icon === 'ControlCameraIcon' &&
                      subItem.submenus?.map((subItems, subIndexs) => {
                        return subItemsMapping(subItems, subIndexs)

                      })
                    }

                    {subItem.icon === 'FolderIcon' &&
                      subItem.submenus?.map((subItems, subIndexs) => {
                        return subItemsMapping(subItems, subIndexs)
                      })
                    }
                    {subItem.icon === 'ContactsIcon' &&
                      subItem.submenus?.map((subItems, subIndexs) => {
                        return subItemsMapping(subItems, subIndexs)
                      })
                    }
                    {subItem.icon === 'CardMembershipIcon' &&
                      subItem.submenus?.map((subItems, subIndexs) => {
                        return subItemsMapping(subItems, subIndexs)
                      })
                    }
                    {subItem.icon === 'AutorenewIcon' &&
                      subItem.submenus?.map((subItems, subIndexs) => {
                        return (
                          <SubMenu key={subIndexs} title={subItems.title}>
                            {subItems.submenus?.map((l, i) => {
                              return subItemsMapping(l, i)
                            })}
                          </SubMenu>
                        )
                      })
                    }

                    {subItem.icon === 'CalendarMonthIcon' &&
                      subItem.menuItem?.map((subItems, subIndexs) => {
                        return subItemsMapping(subItems, subIndexs)
                      })
                    }

                    {subItem.icon === 'CalendarMonthIcon' &&
                      subItem.submenus?.map((l, i) => {
                        return (
                          <SubMenu key={i} title={l.title} >
                            {l.submenus?.map((lx, ix) => {
                              return subItemsMapping(lx, ix)
                            })}
                          </SubMenu>
                        )
                      })}

                    {subItem.icon === 'PictureAsPdfIcon' &&
                      subItem.submenus?.map((subItems, subIndexs) => {
                        return subItemsMapping(subItems, subIndexs)
                      })
                    }

                    {
                      subItem.icon === 'MenuBookIcon' &&
                      subItem.submenus?.map((subItems, subIndexs) => {
                        return subItemsMapping(subItems, subIndexs)
                      })
                    }

                  </SubMenu>
                )
              })}
              {item.menuItem?.map((itm, i) => {
                return (
                  <MenuItem active={urlPath === itm.link} onClick={() => itm.menu === "Logout" ? logout_user() : {}} key={i} icon={itm.icon === "EngineeringIcon" ? <EngineeringIcon /> : itm.icon === "AccountCircleIcon" ? <AccountCircleIcon /> : itm.icon === "ContactsIcon" ? <ContactsIcon /> : itm.icon === "AssignmentIcon" ? <AssignmentIcon /> : <LogoutIcon />}>
                    {itm.menu}
                    <Link to={itm.link} />
                  </MenuItem>
                )
              })}
            </Menu>
          )
        })}
      </ProSidebar>
      <LogoutModal
        open={showLogout}
        onClose={handleClose}
        heading={''}
        paragraph={'Are you sure you want to logout ?'}
        handleClick={handleClickOpen}
      />
    </div >
  )
}

export default Sidebar
