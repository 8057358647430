import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import { toast, ToastContainer } from "react-toastify";
import { TablePagination, Tooltip, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { STATUSES } from "../../redux/common-status/constant";
import * as React from 'react';
import LoaderForm from "../../components/common-function/LoaderForm";
import TablePaginationActions from "../../components/TablePaginationActions";
import { ArrowBack } from "@mui/icons-material";
import { getCandidateExamList } from "../../redux/features/candidateExamListSlice";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import moment from "moment";
import { setListOption, technologyListOption } from "../../redux/features/optionsSlice";
import { scheduleList } from "../../redux/features/masterSlice";
import dataService from "../../redux/services/data.service";
import { createCandidateExamSchedule } from "../../redux/features/candidateExamSlice";

const getUnique = (arr, index) => {

    const unique = arr
        .map(e => e[index])

        // store the keys of the unique objects
        .map((e, i, final) => final.indexOf(e) === i && i)

        // eliminate the dead keys & store unique objects
        .filter(e => arr[e]).map(e => arr[e]);

    return unique;
}

const CandidateExamList = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { status, error, candidateExamList } = useSelector(state => state.candidateExamListSlice)
    const { candidateExamScheduleCreate } = useSelector(state => state.candidate)
    const { sets, technologys } = useSelector(state => state.dropDownOption)
    const { schedules_option } = useSelector(state => state.master)
    const [currentPage, setCurrentPage] = useState(0);
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"filter":{"candidate_id":{"value":${GetLocalstorage.userDetail().id},"operation":"eq"}},"order_by":{"column":"-id"},"skip":0, "limit":10}`)
    const [pageSize, setPageSize] = useState(10);
    const [limit, setLimit] = useState(10);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [autoExamMake, setAutoExamMake] = useState([])

    //error handling
    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setReload(false)
        }
        // eslint-disable-next-line
    }, [error])

    useEffect(() => {
        dispatch(getCandidateExamList(filterQuery))
        dispatch(scheduleList())
        dispatch(setListOption())
        dispatch(technologyListOption())
        autoScheduleCandidateExam()
        // eslint-disable-next-line
    }, [])

    const autoScheduleCandidateExam = async () => {
        let userId = GetLocalstorage.userDetail().id;
        let examList = []
        const request = await dataService.all_candidate_exam_list_by_EXT(`{"filter":{"candidate_id":{"value":${userId},"operation":"eq"}, "schedule_status":{"value":[0,1],"operation":"in"}, "status":{"value":True, "operation":"eq"}},"order_by":{"column":"exam_schedule_id"}, "no_limit":True}`)
        const response = await request.data
        if (response?.data && response.data.rows) {
            examList = getUnique(response.data.rows, 'exam_schedule_id')
            const req = await dataService.all_schedule_list_by_candidate(userId);
            const res = await req.data;
            const array = res?.data && res.data.rows ? res.data.rows : [];

            if (array && array.length > 0) {
                let array2 = [];
                array.map(e => {
                    let matchid = examList.filter(x => x.exam_schedule_id === e.id)
                    if (matchid.length === 0) {
                        array2.push(e)
                    }
                    return array2
                })

                if (array2.length > 0) {
                    let prepaireExamArray = []
                    let count = -0
                    array2.map(e => {
                        if (e.status === true) {
                            count = -1 + count
                            prepaireExamArray.push({
                                candidate: GetLocalstorage.userDetail(),
                                candidate_id: userId,
                                exam_schedule: e,
                                id: count,
                                exam_schedule_id: e.id,
                                exam_schedule_name: e.schedule_name,
                                set_id: e?.set_id,
                                technology_name: e?.set_id && e.set_id.technology && typeof e.set_id.technology === 'object' ? [e.set_id.technology] : e.set_id.technology,
                                status: e.status,
                                scheduled_date: e.start_date && e.start_date !== 'N/A' ? (e.start_date).split('T')[0] : 'N/A',
                                scheduled_time: e.start_date && e.start_date !== 'N/A' ? moment(e.start_date).utc().format('HH:mm:ss') : 'N/A',
                                schedule_status: 9
                            })
                        }
                    })

                    setAutoExamMake(prepaireExamArray)
                }
            }
        }
    }

    // candidate exam schedule submit form data section
    const scheduleExamForCandidate = (data) => {
        let userId = GetLocalstorage.userDetail().id;
        let addvalues = {
            candidate: userId,
            exam_schedule: data.exam_schedule_id,
            created_by: userId,
            status: true,
        }
        setReload(true)
        dispatch(createCandidateExamSchedule(addvalues))

    }


    // candidate exam  schedule create section
    if (reload === true && candidateExamScheduleCreate && candidateExamScheduleCreate.message) {
        if (candidateExamScheduleCreate.status === 200) {
            toast.success(candidateExamScheduleCreate.message)
            dispatch(getCandidateExamList(filterQuery))
            setAutoExamMake([])
            autoScheduleCandidateExam()
        } else {
            toast.error(candidateExamScheduleCreate.message)
        }
        setReload(false)
    }

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getCandidateExamList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getCandidateExamList(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(candidateExamList && candidateExamList.data && candidateExamList.data.rows && candidateExamList.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(getCandidateExamList(JSON.stringify(newFilterData)))
        } else {
            setFilterQuery(`{"filter":{"candidate_id":{"value":${GetLocalstorage.userDetail().id},"operation":"eq"}}, "skip": 0, "limit": 10, "order_by": { "column": "-id" } }`)
        }
    }

    //filter
    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "is" ? 'eq' :
                    filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                        filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue

            let field = filterModel.items[0].columnField === 'technology_id' ? "exam_schedule__set_id" :
                filterModel.items[0].columnField;

            const filterData = `{"filter":{"${[field]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}, "candidate_id":{"value":${GetLocalstorage.userDetail().id},"operation":"eq"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getCandidateExamList(filterData))
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            setFilterQuery(`{"filter":{"candidate_id":{"value":${GetLocalstorage.userDetail().id},"operation":"eq"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`)
            dispatch(getCandidateExamList(`{"filter":{"candidate_id":{"value":${GetLocalstorage.userDetail().id},"operation":"eq"}},"order_by":{"column":"-id"},"skip":0,"limit":${limit}}`))
        }
    }

    const startTest = (data) => {
        navigate('/term-and-condition', { state: { data: data } })
    }


    //columns
    const actionColumn = [
        {
            field: "candidate_id", headerName: "Candidate Name", width: 230, filterable: false, renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        {params.row.candidate?.first_name} {params.row.candidate?.last_name}
                    </div>
                );
            }
        },
        {
            field: "exam_schedule_id", headerName: "Schedule", width: 230, type: 'singleSelect', valueOptions: schedules_option, renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        {params.row.exam_schedule_name ? params.row.exam_schedule_name : "N/A"}
                    </div>
                );
            },
        },
        {
            field: "exam_schedule__set_id", headerName: "Set", width: 230, type: 'singleSelect', valueOptions: sets, renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        {params?.row?.set_id && params.row.set_id.set_name ? params.row.set_id.set_name : "N/A"}
                    </div>
                );
            },
        },
        {
            field: "technology_id", headerName: "Technology", width: 250, type: "singleSelect", valueOptions: technologys,
            renderCell: (params) => {
                return (
                    <div className="ellips-css-data">
                        {params.row.technology_name && params.row.technology_name.length > 0 ? params.row.technology_name.map((e, index) => `${index ? ', ' : ''}${(e.name).trim()}`) : "N/A"}
                    </div>
                );
            },
        },
        {
            field: "scheduled_date", headerName: "Scheduled Date", width: 180, type: "date",
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        {params.row.scheduled_date ? moment(params.row.scheduled_date).format('DD-MM-YYYY') : "N/A"}
                    </div>
                );
            },
        },
        { field: "scheduled_time", headerName: "Scheduled Time", width: 180, },
        {
            field: "is_passed", headerName: "Result Status", width: 160, filterable: false, sortable: false, renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}
                        style={params.row.marks_obtained && params.row.marks_obtained !== 0 || params.row.schedule_status === 7 ?
                            params.row.is_passed === true ? { color: "green" } : { color: "red" } : { color: "unset" }}>
                        {params.row.marks_obtained && params.row.marks_obtained !== 0 || params.row.schedule_status === 7 ?
                            params.row.is_passed === true ? " Pass " : " Fail " : ""}
                    </div>
                );
            },
        },
        {
            field: "status",
            headerName: "Status",
            width: 120,
            type: 'singleSelect',
            valueOptions: [{ value: 'True', label: 'Active' }, { value: 'False', label: 'Inactive' }],
            renderCell: (params) => {
                return (
                    <div className={`cellWithStatus ${params.row.status}`}>
                        {params.row.status === true ? 'Active' : 'Inactive'}
                    </div>
                );
            },
        },
        {
            field: "action",
            headerName: "Action",
            width: 200,
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                let beginningDateTime = moment(params.row.scheduled_date + ' ' + params.row.scheduled_time, 'YYYY-MM-DD h:mma');
                let currentDateTime = moment(moment().format('YYYY-MM-DD H:m:s'), 'YYYY-MM-DD h:mma');
                return (
                    <div className="cellAction">
                        {(params.row.schedule_status === 1 || params.row.schedule_status === 0) && beginningDateTime.isSameOrBefore(currentDateTime) ?
                            <Button style={{ backgroundColor: '#0052cc', color: '#fff' }} onClick={() => startTest(params.row)}>Take Test</Button>
                            :
                            params.row.schedule_status === 9 ?
                                <Button className="cellAction" style={{ backgroundColor: '#a79b64', color: '#fff' }} onClick={() => scheduleExamForCandidate(params.row)}>Schedule Exam</Button>
                                :
                                <Button className="cellAction" style={params.row.schedule_status === 2 ? { backgroundColor: '#544d4d', color: '#fff', cursor: "not-allowed" } :
                                    params.row.schedule_status === 3 ? { backgroundColor: 'green', color: '#fff', cursor: "not-allowed" } :
                                        params.row.schedule_status === 4 ? { backgroundColor: 'darkred', color: '#fff', cursor: "not-allowed" } :
                                            { color: '#000' }
                                }
                                    disabled>
                                    {params.row.schedule_status === 2 ? "Inprogress" :
                                        params.row.schedule_status === 3 ? "Evaluation Pending" :
                                            params.row.schedule_status === 5 ? "Evaluator Assigned" :
                                                params.row.schedule_status === 6 ? "Evaluation Inprogress" :
                                                    params.row.schedule_status === 7 ? "Result Declared" :
                                                        params.row.schedule_status === 4 ? "Cancelled" : "Take Test"}
                                </Button>

                        }
                    </div>
                );
            },
        },
    ];

    return (
        <div className="master">
            <Sidebar />
            <div className="masterContainer">
                <Navbar />

                <ToastContainer />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>My Test List</h3>
                        <div>
                            <Tooltip title="Back">
                                <Link to={-1} className="btn-add-new"><ArrowBack /> </Link>
                            </Tooltip>
                        </div>
                    </div>
                    {(status === STATUSES.LOADING || reload === true) && <LoaderForm />}
                    <DataGrid
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={candidateExamList?.data && candidateExamList.data.rows ? autoExamMake.length > 0 ? autoExamMake.concat(candidateExamList.data.rows) : candidateExamList.data.rows : autoExamMake.length > 0 ? autoExamMake : []}
                        columns={actionColumn}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        onFilterModelChange={onFilterChange}
                        disableSelectionOnClick
                        components={{
                            Pagination: CustomPagination,
                        }}
                        onSortModelChange={(sort) => handleSort(sort)}
                    />
                </div>
            </div>
        </div>
    )
}

export default CandidateExamList