import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./master.scss"
import { toast, ToastContainer } from "react-toastify";
import { Dialog, DialogContent, TablePagination, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { questionLevelColumns } from "../../components/datatable-coulms/tableCoulmns";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { STATUSES } from "../../redux/common-status/constant";
import CONSTANTS from "../../components/constant/constantComponents";
import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../components/TablePaginationActions";
import { Add, ArrowBack, Close } from "@mui/icons-material";
import { InputFields, InputRadioGroupFields } from "../../components/common-function/InputFields";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import { getQuestionLevelList, questionlevelArchive, questionlevelCreate, questionlevelEdit, questionlevelUpdate } from "../../redux/features/masterSlice";
import CustomFilterFn from "../../components/datatable-coulms/customFilter";


const initialFormData = Object.freeze({
    level_name: "",
    status: "",
    experience_from_years: '',
    experience_from_months: '',
    experience_to_years: '',
    experience_to_months: ''
});

const formErrData = Object.freeze({
    level_nameErr: "Experience level name is required",
    statusErr: "Status is required"
});


const QuestionLevel = () => {
    const { REACT_APP_ENV } = process.env;

    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const [archiveId, setArchiveId] = useState(0)
    const [archiveOpen, setArchiveOpen] = useState(false)
    const { status, error, questionlevel_List, questionlevel_Create, questionlevel_Edit, questionlevel_Update, questionlevel_Archive } = useSelector(state => state.master)
    const [currentPage, setCurrentPage] = useState(0);
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":10}`)
    const [pageSize, setPageSize] = useState(10);
    const [limit, setLimit] = useState(10);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });

    //error handling
    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setReload(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    useEffect(() => {
        dispatch(getQuestionLevelList(filterQuery))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        if (ID) { setID(0) }
        setOpen(false);
        setFormValues(initialFormData);;
        setErrStatus(false)
    }

    if (reload === "submit" && status === `${STATUSES.SUCCESS}_questionlevel_Update` && questionlevel_Update && questionlevel_Update.message) {
        if (questionlevel_Update.status === 200) {
            toast.success(questionlevel_Update.message)
            dispatch(getQuestionLevelList(filterQuery))
            handleClose()
        } else {
            toast.error(questionlevel_Update.message)
        }
        setReload(false)
    }

    if (reload === "submit" && status === `${STATUSES.SUCCESS}_questionlevel_Create` && questionlevel_Create && questionlevel_Create.message) {
        if (questionlevel_Create.status === 200) {
            toast.success(questionlevel_Create.message)
            dispatch(getQuestionLevelList(filterQuery))
            handleClose()
        } else {
            toast.error(questionlevel_Create.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_questionlevel_Archive` && questionlevel_Archive && questionlevel_Archive.message) {
        if (questionlevel_Archive.status === 200) {
            toast.success(questionlevel_Archive.message)
            dispatch(getQuestionLevelList(filterQuery))
        } else {
            toast.error(questionlevel_Archive.message)
        }
        setReload(false)
    }

    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        setFormValues({ ...formValues, [name]: value })
    }

    const submitData = (e) => {
        e.preventDefault()
        if (formValues.level_name === "" || formValues.status === "") {
            setErrStatus(true)
        }

        if (formValues.level_name !== "" && formValues.status !== "") {
            const ces_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_CES`))
            let userId = ces_user.user?.data && ces_user.user.data.id
            let addvalues = { ...formValues, created_by: userId }
            setErrStatus(false)
            setReload("submit")
            if (ID) {
                dispatch(questionlevelUpdate({ ID, addvalues }))
            } else {
                dispatch(questionlevelCreate(addvalues))
            }
        }
    }

    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}` && questionlevel_Edit && questionlevel_Edit.data) {
        let editData = questionlevel_Edit.data[0];
        setFormValues({
            level_name: editData.level_name,
            status: editData.status,
            experience_from_years: editData.experience_from_years.toString(),
            experience_from_months: editData.experience_from_months.toString(),
            experience_to_years: editData.experience_to_years.toString(),
            experience_to_months: editData.experience_to_months.toString()
        })
        setID(editData.id)
        setOpen(true)
        setReload(false)
    }

    const handleClickEdit = (id) => {
        dispatch(questionlevelEdit(id))
        setReload('edit_data')
    }

    const handleArchive = (id) => {
        setArchiveId(id)
        setArchiveOpen(true)
    }

    const handleClickArchive = (id) => {
        setReload(true)
        setArchiveOpen(false)
        dispatch(questionlevelArchive(id))
    }

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getQuestionLevelList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getQuestionLevelList(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(questionlevel_List?.data && questionlevel_List.data.rows && questionlevel_List.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(getQuestionLevelList(JSON.stringify(newFilterData)))
        } else {
            setFilterQuery(`{ "skip": 0, "limit": 10, "order_by": { "column": "-id" } }`)
        }
    }


    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: "120",
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <UcActionComponent
                            deleteIconName="ArchiveIcon"
                            moduleName="question-level"
                            rowid={params.row.id}
                            addMoreData=""
                            editLinkUrl=""
                            viewLinkUrl=""
                            isDelete={true}
                            editButton={handleClickEdit}
                            viewButton={false}
                            deleteButton={handleArchive} />
                    </div>
                );
            },
        },
    ];

    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                    filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue === 'is' || filterModel.items[0].operatorValue  === 'equals' ? 'eq' :  filterModel.items[0].operatorValue === 'isAnyOf' ? 'in' : filterModel.items[0].operatorValue

            const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":${JSON.stringify(filterModel.items[0].value)},"operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getQuestionLevelList(filterData))
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            //setPageSize(10)
            setFilterQuery(`{"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`)
            dispatch(getQuestionLevelList(`{"order_by":{"column":"-id"},"skip":0,"limit":${limit}}`))
        }
    }
    let experienceYearArr = [];
    let experienceMonthArr = [];

    for (let i = 0; i <= 60; i++) {
        experienceYearArr.push(i);
    }

    for (let i = 0; i <= 11; i++) {
        experienceMonthArr.push(i);
    }


    return (
        <div className="master">
            <Sidebar />
            <div className="masterContainer">
                <Navbar />

                <ToastContainer />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Experience Level List</h3>
                        <div>
                            <Tooltip title="Add New Experience Level">
                                <button onClick={handleOpen} className="btn-add-new"><Add /> </button>
                            </Tooltip>
                            <Tooltip title="Back">
                                <Link to={-1} className="btn-add-new"><ArrowBack /> </Link>
                            </Tooltip>
                        </div>
                    </div>
                    {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') && <LoaderForm />}
                    <DataGrid
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={questionlevel_List?.data && questionlevel_List.data.rows ? questionlevel_List.data.rows : []}
                        columns={CustomFilterFn(questionLevelColumns).concat(actionColumn)}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        onFilterModelChange={onFilterChange}
                        filterMode="server"
                        disableSelectionOnClick
                        components={{
                            Pagination: CustomPagination,
                        }}
                        onSortModelChange={(sort) => handleSort(sort)}
                    />
                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>{ID ? 'Edit' : 'Add New'} Experience Level</DialogTitle>

                    <DialogContent>
                        <form>
                            <div className="add-new-master-form">
                                <InputFields
                                    label={"Experience Level Name"}
                                    name="level_name"
                                    defaultValue={formValues.level_name}
                                    type="text"
                                    placeholder="Experience Level Name"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.level_nameErr}
                                />
                                <InputRadioGroupFields
                                    label={"Status"}
                                    name="status"
                                    defaultValue={formValues.status}
                                    type="text"
                                    placeholder="Status"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.statusErr}
                                />

                                <div className="formInput" >
                                    <label >
                                        Experience From Year
                                    </label>
                                    <select
                                        name="experience_from_years"
                                        onChange={handleChange}
                                        value={formValues.experience_from_years}
                                    >
                                        {experienceYearArr.map((e, i) => {
                                            return (<option value={e} key={i}>{e}</option>)
                                        })}
                                    </select>
                                </div>
                                <div className="formInput" >
                                    <label >
                                        Experience From Month
                                    </label>
                                    <select
                                        name='experience_from_months'
                                        onChange={handleChange}
                                        value={formValues.experience_from_months}
                                    >
                                        {experienceMonthArr.map((e, i) => {
                                            return (<option value={e} key={i}>{e}</option>)
                                        })}
                                    </select>
                                </div>
                                <div className="formInput" >
                                    <label >
                                        Experience To Year
                                    </label>
                                    <select
                                        name='experience_to_years'
                                        onChange={handleChange}
                                        value={formValues.experience_to_years}

                                    >
                                        {experienceYearArr.map((e, i) => {
                                            return (<option value={e} key={i}>{e}</option>)
                                        })}
                                    </select>
                                </div>
                                <div className="formInput" >
                                    <label >
                                        Experience To Month
                                    </label>
                                    <select
                                        name={"experience_to_months"}
                                        onChange={handleChange}
                                        value={formValues.experience_to_months}

                                    >
                                        {experienceMonthArr.map((e, i) => {
                                            return (<option value={e} key={i}>{e}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>
                            <button
                                className={reload === "submit" ? "submit-btn-disabled" : "submit-modal"}
                                onClick={submitData}
                                disabled={reload === "submit"}
                            >
                                {ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}
                            </button>
                        </form>
                    </DialogContent>
                </Dialog>
                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={'Archive'}
                    paragraph={'Are you sure to archive Experience level'}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />
            </div>
        </div >
    )
}

export default QuestionLevel