import React, { useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import html2canvas from "html2canvas";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";


const ScreenCaptureTest = () => {
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);
  const screenshotsRef = useRef([]);
  const targetRef = useRef(null);
  const [intervalId, setIntervalId] = useState(null);
  const [cameraAccess,setCameraAccess]= useState(true)
  const [error,setError]=useState('')

  const user = LocalStorageDataService.userDetail();
  const navigate = useNavigate();

  useEffect(() => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      setError('Webcam recording is not supported in this browser. Use latest version of Google Chrome or Firefox');
    } else {
    startRecording();
    const screenshotInterval = setInterval(captureScreenshot, 15000);
    setIntervalId(screenshotInterval);

    return () => {
      clearInterval(screenshotInterval);
    };
  };
  }, []);

  const startRecording = () => {
    const constraints = { audio: true, video: true };
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorder.ondataavailable = handleDataAvailable;
        mediaRecorder.start();

        mediaRecorderRef.current = mediaRecorder;
      })
      .catch((error) => {
        setError('Failed to access webcam or microphone. Allow the permissions');
        setCameraAccess(false);
      });
  };

  const handleDataAvailable = (event) => {
    if (event.data.size > 0) {
      chunksRef.current.push(event.data);
    }
  };

  // const captureScreenshot = () => {
  //   const width = document.documentElement.scrollWidth;
  //   const height = document.documentElement.scrollHeight;

  //   html2canvas(targetRef.current, { width, height })
  //     .then((canvas) => {
  //       const screenshot = canvas.toDataURL();
  //       screenshotsRef.current.push(screenshot);
  //     })
  //     .catch((error) => console.log(error));
  // };

  
  const captureScreenshot = async () => {
    const width = document.documentElement.scrollWidth;
    const height = document.documentElement.scrollHeight;

    try {
      const canvas = await html2canvas(targetRef.current, { width, height });
      const screenshot = canvas.toDataURL();

      const blob = await fetch(screenshot).then(response => response.blob());
      screenshotsRef.current.push(blob);
    } catch (error) {
      console.log('Error capturing screenshot:', error);
    }
  };

  const sendToBackend = async () => {
    const videoFormData = new FormData();
    videoFormData.append(`video`, chunksRef.current[0]);
    // console.log(chunksRef)

    videoFormData.append(`screenshot`, screenshotsRef.current[0]);
    // console.log(screenshotsRef)
    videoFormData.append('userId', user.user_id)

    try {
      await axios.post('https://dummy.restapiexample.com/api/v1/create', videoFormData);
      chunksRef.current = [];
      screenshotsRef.current = [];
    } catch (error) {
      console.log('Error sending data to backend:', error);
    }

  };



  // const sendToBackend = () => {
  //   const payload = {
  //     video: chunksRef.current,
  //     screenshots: screenshotsRef.current,
  //   };

  //   console.log(payload);

  //   chunksRef.current = [];
  //   screenshotsRef.current = [];
  // };

  useEffect(() => {
    const videoInterval = setInterval( async() => {
      stopRecording();
      await sendToBackend();
      startRecording();
    }, 15000);

    return () => {
      clearInterval(videoInterval);
    };
  }, []);

  const stopRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
    }
  };
  return (
    <div className="new" ref={targetRef}>
      <Sidebar />
      <div className="newContainer">
        <Navbar />

        <div className="top-headings">
          <h3>Webcam Recorder</h3>

        </div>

        {error ? (
          <div className="error-msg">{error}</div>
        ) : (
          <div className="termand-condition-data" style={{ textAlign: "center" }}>
            <h4>Test Started</h4>
            <h5>Instruction</h5>
            <p>Make sure your face will properly visible, straight, and clear.</p>
          </div>
        )}

        {cameraAccess && (
          <Webcam
            audio={false}
            ref={webcamRef}
            style={{ display: 'none' }}
          />
        )}
      </div>
    </div>
  );
};

export default ScreenCaptureTest;