import React, { useCallback, useRef, useState,useEffect } from "react";
import "./candidatekyc.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Button, IconButton } from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";
import Webcam from "react-webcam";
import { Next } from "react-bootstrap/esm/PageItem";
// import { Link } from "react-router-dom";
import axios from "axios";
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
import { useNavigate } from "react-router-dom";


const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user",
};

const CandidateKyctest = () => {
  const [photo, setPhoto] = useState(null);
  const [uniqueIDCard, setUniqueIDCard] = useState(null);
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);

  const user = LocalStorageDataService.userDetail();
  const navigate = useNavigate();

  const drawOverlay = useCallback(() => {
    const webcam = webcamRef.current.video;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const videoWidth = webcam.videoWidth;
    const videoHeight = webcam.videoHeight;
    const canvasWidth = videoWidth;
    const canvasHeight = videoHeight;

    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    ctx.clearRect(0, 0, canvasWidth, canvasHeight);
    ctx.drawImage(webcam, 0, 0, canvasWidth, canvasHeight);

    ctx.lineWidth = 3;
    ctx.strokeStyle = "voilet";

    const faceX = canvasWidth / 2;
    const faceY = canvasHeight / 2;
    const faceWidth = canvasWidth * 0.4;
    const faceHeight = canvasHeight * 0.8;

    
    ctx.beginPath();
    ctx.ellipse(
      faceX,
      faceY,  
      faceWidth / 2,
      faceHeight / 2,
      0,
      0,
      2 * Math.PI
    );
    ctx.stroke();

    requestAnimationFrame(drawOverlay);
  }, []);

  useEffect(() => {
    requestAnimationFrame(drawOverlay);
  }, [drawOverlay]);


  const capturePhoto = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
  
   
    const image = new Image();
    image.onload = () => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
  
      canvas.width = image.width;
      canvas.height = image.height;
  
      context.drawImage(image, 0, 0);
  
      // `quality` validation
      const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;
      const pixelCount = canvas.width * canvas.height;
      let lowLightPixels = 0;
  
      
      for (let i = 0; i < pixelCount; i++) {
        const pixelIndex = i * 4;
        const r = data[pixelIndex];
        const g = data[pixelIndex + 1];
        const b = data[pixelIndex + 2];
        const brightness = (r + g + b) / 3;
  
        if (brightness < 50) {
          lowLightPixels++;
        }
      }
  
      const lowLightThreshold = 0.4; 
      const lowLightPercentage = lowLightPixels / pixelCount;
  
      if (lowLightPercentage > lowLightThreshold) {
       
        alert("Please capture the photo in proper lighting conditions.");
      } else {
    
        setPhoto(imageSrc);
      }
    };
  
    image.src = imageSrc;
  }, [webcamRef]);

  const captureUniqueIDCard = useCallback(() => {
    const uniqueIDCardImg = webcamRef.current.getScreenshot();


    const image = new Image();
    image.onload = () => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
  
      canvas.width = image.width;
      canvas.height = image.height;
  
      context.drawImage(image, 0, 0);
  
      // quality validation
      const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;
      const pixelCount = canvas.width * canvas.height;
      let lowLightPixels = 0;
  
      
      for (let i = 0; i < pixelCount; i++) {
        const pixelIndex = i * 4;
        const r = data[pixelIndex];
        const g = data[pixelIndex + 1];
        const b = data[pixelIndex + 2];
        const brightness = (r + g + b) / 3;
  
        if (brightness < 50) {
          lowLightPixels++;
        }
      }
  
      const lowLightThreshold = 0.4; 
      const lowLightPercentage = lowLightPixels / pixelCount;
  
      if (lowLightPercentage > lowLightThreshold) {
       
        alert("Please capture the photo in proper lighting conditions.");
      } else {


    setUniqueIDCard(uniqueIDCardImg);
      }
    }
    image.src = uniqueIDCardImg
  }, [webcamRef]);

  const sendToNextPage = async () => {
    const formData = new FormData();
    if (photo) {
      const imageBlob = await fetch(photo).then(response => response.blob());
      formData.append("image", imageBlob, "actual_image_file_name.jpg");
    }
    if (uniqueIDCard) {
      const idCardBlob = await fetch(uniqueIDCard).then(response => response.blob());
      formData.append("idCard", idCardBlob, "actual_id_card_file_name.jpg");
    }
    formData.append("id", user.user_id)
    try {
      const response = await axios.post("https://dummy.restapiexample.com/api/v1/create", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      //  console.log(formData)
    } catch (error) {
      
      console.log(error);
    }
    navigate("/screen-capture-test");
  };

  // const dataURItoBlob = (dataURI) => {
  //   const byteString = atob(dataURI.split(",")[1]);
  //   const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  //   const ab = new ArrayBuffer(byteString.length);
  //   const ia = new Uint8Array(ab);

  //   for (let i = 0; i < byteString.length; i++) {
  //     ia[i] = byteString.charCodeAt(i);
  //   }

  //   return new Blob([ab], { type: mimeString });
  // };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />

        <div className="top-headings">
          <h3>Complete your KYC</h3>
        </div>

        <div className="termand-condition-data" style={{ textAlign: "center" }}>
          <h4>Capture your photo</h4>
          <h5>Instruction</h5>
          <p>Make sure your face will proper visibile, straight and clear.</p>
          <div style={{ margin: "20px", textAlign: "left", display: "flex" }}>
            {photo && (
              <div className="capturedPhoto" style={{ margin: "20px", boxShadow : "2px 2px 15px 0px #e0e0e0", borderRadius: "6px", padding: "21px", textAlign: "center"}}>
                <h6>Captured Photo</h6>
                <img
                  src={photo}
                  style={{ width: "200px", height: "200px", objectFit: "cover" }}
                  alt="Captured Photo"
                />

                {uniqueIDCard && (
                  <>
                <h6 style={{ margin: "20px"}}>Captured PAN / AADHAAR</h6>
                <img
                  src={uniqueIDCard}
                  style={{ width: "200px", height: "200px", objectFit: "cover" }}
                  alt="Captured PAN / AADHAAR"
                />
                </>
                )}

              </div>
            )}
            {photo && uniqueIDCard && (
            <div>
              <h1 style={{ color: "green", marginTop: "176px", fontSize: "36px" }}>
                Your KYC successfully completed!
              </h1>
              {/* <a href="/screen-capture-test"> */}
                <Button
                style={{ width: "250px", margin: "23px 178px" }}
                onClick={sendToNextPage}
                variant="contained"
              >
                Next
              </Button>
              {/* </a> */}
            </div>
          )} 
                                            
            {/* {uniqueIDCard && (
              <div className="capturedPhoto" style={{ margin: "20px", boxShadow : "2px 2px 15px 0px #e0e0e0", borderRadius: "6px", padding: "21px", textAlign: "center"}}>
                <h6>Captured PAN / AADHAAR</h6>
                <img
                  src={uniqueIDCard}
                  style={{ width: "200px", height: "200px", objectFit: "cover" }}
                  alt="Captured PAN / AADHAAR"
                />
                </div>
            )}   */}
          </div>

          {(!photo || !uniqueIDCard) && (
          <div className="webcam-container" style={{ position: "relative" }}>
              <Webcam
                audio={false}
                height={400}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                width={1000}
                mirrored={true}
                videoConstraints={videoConstraints}
              />
              {!photo && (
              <canvas
                ref={canvasRef}
                style={{
                  position: "absolute",
                  top: 0,
                  left: "14.5%",
                  width: "71.2%",
                  height: "98.2%",
                  pointerEvents:"none",
                }}
              />
              )}
              {photo && !uniqueIDCard && (
              <canvas
                style={{
                  position: "absolute",
                  top: 0,
                  left: "14.5%",
                  width: "71.2%",
                  height: "98.2%",
                  pointerEvents:"none",
                }}
              />
              )}
            </div>
           )}  

          {/* {photo && uniqueIDCard && (
            <div>
              <h1 style={{ color: "green" }}>
                Your KYC successfully completed!
              </h1>
              <a href="/term-and-condition"><Button
                style={{ width: "250px" }}
                onClick={sendToNextPage}
                variant="contained"
              >
                Next
              </Button></a>
            </div>
          )}  */}

          {!photo && (
            <Button
              style={{ width: "250px" }}
              onClick={capturePhoto}
              variant="contained"
              endIcon={<PhotoCamera />}
            >
              Capture Photo
            </Button>
          )} 

          {photo && !uniqueIDCard && (
            <Button
              style={{ width: "250px" }}
              onClick={captureUniqueIDCard}
              variant="contained"
              endIcon={<PhotoCamera />}
            >
              Capture Authorize ID
            </Button>
          )} 
        </div>
      </div>
    </div>
  );
};

export default CandidateKyctest;
