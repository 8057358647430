import { useState, useMemo } from "react";
import Filter from "../Filter";
import { getGridSingleSelectOperators } from "@mui/x-data-grid";

 const CustomFilterFn = (actionColumn) => {
    const [options, setOptions] = useState([{ value: "True", label: 'Active' }, { value: "False", label: 'Inactive' }])
    const [defaultValue, setDefaultValue] = useState({});

    const columns = useMemo(() =>
        actionColumn?.map((col) => {
            if (col.field === 'status' || col.field === 'status_id' || col.field === "technology__id" || col.field === "technology_id" || col.field === "level__id" || col.field === 'set__id' || col.field === "question_level_id" || col.field === 'question_type_id') {
                return {
                    ...col,
                    filterOperators: getGridSingleSelectOperators()
                        .filter((operator) => operator.value !== "isAnyOf" && operator.value !== "not")
                        .map((operator) => ({
                            ...operator,
                            InputComponent: operator.InputComponent
                                ? CustomFilterInputValue
                                : undefined
                        }))
                };
            }
            return col;
        }),
        // eslint-disable-next-line
        [actionColumn]
    );

    function CustomFilterInputValue(props) {
        const { item, applyValue } = props;
        const handleFilterChange = (evt) => {
            if (evt) {
                setDefaultValue(evt)
                const { value } = evt;
                applyValue({ ...item, value: value });
            }
        };
        return (
            <Filter options={options} onChange={handleFilterChange} defaultValue={defaultValue} />
        );
    }
  return columns;
}

export default CustomFilterFn;