import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./master.scss"
import { toast, ToastContainer } from "react-toastify";
import { Dialog, DialogContent, TablePagination, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IndustryDepartmentCreate, IndustryDepartmentEdit, IndustryDepartmentUpdate, getIndustryDepartmentList, getIndustryList, getAllIndustryList } from "../../redux/features/masterSlice";
import { getDepartmentList } from "../../redux/features/departmentSlice"
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { STATUSES } from "../../redux/common-status/constant";
import CONSTANTS from "../../components/constant/constantComponents";
import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../components/TablePaginationActions";
import { Add, Close } from "@mui/icons-material";
import CustomNoRowsOverlay from "../../components/common-function/CustomEmptyOverlay";
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
import CustomeTooltip from "../../components/common-function/CustomeTooltip";
import Select from 'react-select';

const initialFormData = Object.freeze({
    industry: "",
    departments: "",
    status: true,
    created_by: LocalStorageDataService.userDetail() && LocalStorageDataService.userDetail().id,
});

const formErrData = Object.freeze({
    industry_Err: "Industry name is required",
    department_Err: "Department is required",
    statusErr: "Status is required"
});


const IndustryDepartment = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    // const [archiveId, setArchiveId] = useState(0)
    // const [archiveOpen, setArchiveOpen] = useState(false)
    const { status, error, industry_department_List, industry_department_Create, industry_department_Edit, industry_department_Update, industry_department_Archive, industry_List, industryOptions } = useSelector(state => state.master)
    const { departmemtOptionList } = useSelector(state => state.department)
    const [currentPage, setCurrentPage] = useState(0);
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"filter": {"status": {"value": "True", "operation": "eq" } },"order_by":{"column":"-id"},"skip":0, "limit":25}`)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });

    useEffect(() => {
        dispatch(getIndustryDepartmentList(filterQuery))
        dispatch(getIndustryList(`{ "filter": {"status": {"value": "True", "operation": "eq" } },"no_limit": True}`))
        dispatch(getDepartmentList())
        dispatch(getAllIndustryList())
        if (status === STATUSES.FAILURE && error) {
            setReload(false)
            errorHandling(error, navigate)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getDropdownEditData = (id, name, data, lebel_key) => {
        if (id) {
            let newObject = null
            data && data.length > 0 && data.map(x => {
                return (x.id === id) && (newObject = { value: x.id, label: x[lebel_key], name })
            })
            return newObject
        }
    }

    const getUnique = (arr, index) => {

        const unique = arr
            .map(e => e[index])

            // store the keys of the unique objects
            .map((e, i, final) => final.indexOf(e) === i && i)

            // eliminate the dead keys & store unique objects
            .filter(e => arr[e]).map(e => arr[e]);

        return unique;
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_industry_department_Update` && industry_department_Update && industry_department_Update.message) {
        if (industry_department_Update.status === 200) {
            toast.success(industry_department_Update.message)
            dispatch(getIndustryDepartmentList(filterQuery))
        } else {
            toast.error(industry_department_Update.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_industry_department_Create` && industry_department_Create && industry_department_Create.message) {
        if (industry_department_Create.status === 200) {
            toast.success(industry_department_Create.message)
            dispatch(getIndustryDepartmentList(filterQuery))
        } else {
            toast.error(industry_department_Create.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_industry_department_Archive` && industry_department_Archive && industry_department_Archive.message) {
        if (industry_department_Archive.status === 200) {
            toast.success(industry_department_Archive.message)
            dispatch(getIndustryDepartmentList(filterQuery))
        } else {
            toast.error(industry_department_Archive.message)
        }
        setReload(false)
    }


    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        if (ID) { setID(0); setFormValues(initialFormData) } setOpen(false); setFormValues(initialFormData);
        setErrStatus(false)
    }

    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        setFormValues({ ...formValues, [name]: value })

    }

    const handleChangeOnDepartment = (evt) => {
        if (evt && evt.length > 0) {
            setFormValues({ ...formValues, "departments": evt });
        } else {
            setFormValues({ ...formValues, "departments": "" });
        }
    }


    const submitData = (e) => {
        e.preventDefault()

        let departmentArray = []
        if (formValues.departments && formValues.departments.length > 0) {
            formValues.departments.map(e => departmentArray.push(Number(e.value)))
        }

        if (
            formValues.industry === "" ||
            //   formValues.department === "" ||
            formValues.status === ""
        ) {
            setErrStatus(true);
        }

        if (
            formValues.industry !== "" &&
            //   formValues.department !== "" &&
            formValues.status !== ""
        ) {

            let addvalues;

            if (typeof formValues.industry === "object") {
                addvalues = { ...formValues, industry: formValues.industry.value, departments: departmentArray };
            } else {
                addvalues = { ...formValues, departments: departmentArray };
            }
            setErrStatus(false);
            setReload(true);
            handleClose();
            // if (ID) {
            //     dispatch(IndustryDepartmentUpdate({ ID, addvalues }));
            // } else {
            //     dispatch(IndustryDepartmentCreate(addvalues));
            // }
            dispatch(IndustryDepartmentCreate(addvalues));
        }
    }

    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}` && industry_department_Edit && industry_department_Edit.data) {
        let editData = industry_department_Edit.data?.rows && industry_department_Edit.data.rows.length > 0 && industry_department_Edit.data.rows[0];

        let departmentArray = []
        if (editData?.departments) {
            editData.departments.length > 0 && editData.departments.map(e => {
                return departmentArray.push({
                    label: e.name,
                    value: e.id
                })
            })
        }

        setFormValues({
            industry: getDropdownEditData(editData.industry.id ?? null, "industry", industry_List.data.rows ?? [], "industry_name"),
            departments: editData?.department?.map((departments) => getDropdownEditData(departments.id ?? null, "departments", departmemtOptionList, "label")),
            status: editData.status,
            id: editData?.id,
            created_by: LocalStorageDataService.userDetail() && LocalStorageDataService.userDetail().id,
        })
        setID(editData.id)
        setOpen(true)
        setReload(false)
    }

    const handleClickEdit = (data) => {
        let industry_id = data?.id;
        dispatch(IndustryDepartmentEdit(industry_id))
        setReload('edit_data')
    }

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getIndustryDepartmentList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getIndustryDepartmentList(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(industry_department_List?.data && industry_department_List.data.rows && industry_department_List.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(getIndustryDepartmentList(JSON.stringify(newFilterData)))
        } else {
            setFilterQuery(`{ "skip": 0, "limit": 10, "order_by": { "column": "-id" } }`)
        }
    }


    const actionColumn = [
        {
            field: "industry_id",
            headerName: "Industry",
            width: 300,
            type: "singleSelect", valueOptions: industryOptions,
            renderCell: (params) => {
                return <div>
                     <CustomeTooltip
                        title={params.row.industry && params.row.industry.industry_name ? params.row.industry.industry_name : "-"}
                        data={params.row.industry && params.row.industry.industry_name? params.row.industry && params.row.industry.industry_name : "-"}
                    />
                </div>;
            },
        },
        {
            field: "department_id",
            headerName: "Departments",
            width: 800,
            type: "singleSelect", valueOptions: departmemtOptionList,
            renderCell: (params) => {
                return <div>
                    <CustomeTooltip
                        title={params.row.department && params.row.department.length > 0 ? getUnique(params.row.department, 'id').map((e, index) => `${index ? ', ' : ''}${(e.name).trim()}`) : "-"}
                        data={params.row.department && params.row.department.length > 0 ? getUnique(params.row.department, 'id').map((e, index) => `${index ? ', ' : ''}${(e.name).trim()}`) : "-"}
                    />
                </div>;
            },
        },
        // {
        //     field: "status",
        //     headerName: "Status",
        //     width: 160,
        //     renderCell: (params) => {
        //         return (
        //             <div className={`cellWithStatus ${params.row.status}`}>
        //                 {params.row.status === true ? "Active" : "Inactive"}
        //             </div>
        //         );
        //     },
        // },
        {
            field: "action",
            headerName: "Action",
            width: "120",
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <UcActionComponent
                            // deleteIconName="ArchiveIcon"
                            moduleName="industry-department"
                            rowid={params.row.id}
                            addMoreData=""
                            editLinkUrl=""
                            viewLinkUrl=""
                            // isDelete={true}
                            editButton={() => handleClickEdit(params.row.industry)}
                            viewButton={false}
                        // deleteButton={() => handleArchive(params.row)} 
                        />
                    </div>
                );
            },
        },
    ];

    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                    filterModel.items[0].operatorValue === "is" ? 'eq'
                        : filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
            const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getIndustryDepartmentList(filterData))
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            //setPageSize(10)
            setFilterQuery(`{"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`)
            dispatch(getIndustryDepartmentList(`{"order_by":{"column":"-id"},"skip":0,"limit":${limit}}`))
        }
    }

    let i = 0;
    return (
        <div className="master">
            <Sidebar />
            <div className="masterContainer">
                <Navbar />

                <ToastContainer />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Industry - Department List</h3>
                        <div>
                            <Tooltip title={"Add Industry Department"}>
                                <button onClick={handleOpen} className="btn-add-new ">
                                    <Add
                                    />
                                </button>
                            </Tooltip>
                            {/* <RefreshButton api={getIndustryDepartmentList} filter={filterQuery} style={{ marginTop: 0 }} /> */}
                        </div>
                    </div>
                    {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') && <LoaderForm />}
                    <DataGrid
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={industry_department_List?.data && industry_department_List.data.rows ? industry_department_List.data.rows : []}
                        columns={actionColumn}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        onFilterModelChange={onFilterChange}
                        disableSelectionOnClick
                        components={{
                            Pagination: CustomPagination,
                            NoRowsOverlay: CustomNoRowsOverlay
                        }}
                        onSortModelChange={(sort) => handleSort(sort)}
                        getRowId={(row) => row + i++}
                        filterMode="server"
                    />
                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                    PaperProps={{
                        sx: {
                            minHeight: 500
                        }
                    }}
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>Industry Department</DialogTitle>

                    <DialogContent>
                        <form>
                            <div className="add-new-master-form">
                                <div className="formInput">
                                    <label >
                                        Select Industry
                                    </label>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        divstyle={{ flexBasis: '100%' }}
                                        placeholder={'Select Industry'}
                                        onChange={handleChange}
                                        defaultValue={formValues.industry}
                                        // value={formValues.industry}
                                        sx={{ width: "100%" }}
                                        isSearchable={true}
                                        name={'industry'}
                                        options={
                                            industry_List?.data && industry_List.data.rows.map((option) => {
                                                return {
                                                    label: option.industry_name,
                                                    value: option.id,
                                                    name: 'industry'
                                                }
                                            }).reverse()
                                        }
                                    />
                                    {errStatus === true && formValues && formValues.industry === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.industry_Err}</p>}
                                </div>

                                {
                                    formValues.industry ?
                                        <div className="formInput">
                                            <label >
                                                Department
                                            </label>
                                            <Select
                                                divstyle={{ flexBasis: '100%' }}
                                                isMulti
                                                className="basic-single"
                                                classNamePrefix="select"
                                                placeholder="Select Department"
                                                defaultValue={formValues.departments}
                                                // value={formValues.department}
                                                isSearchable={true}
                                                name={"departments"}
                                                options={
                                                    departmemtOptionList?.map((option) => {
                                                        return { label: option.label, value: option.value, name: "departments" }
                                                    })
                                                }
                                                onChange={handleChangeOnDepartment}
                                            // onChange={handleChange}
                                            />
                                            {errStatus === true && formValues && formValues.departments === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.department_Err}</p>}
                                        </div>
                                        : <></>
                                }

                                {/* <InputRadioGroupFields
                                    label={"Status"}
                                    name="status"
                                    defaultValue={formValues.status}
                                    type="text"
                                    placeholder="Status"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.statusErr}
                                /> */}

                            </div>
                            <button className="submit-modal" onClick={submitData} disabled={reload === true}>{ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}</button>
                        </form>
                    </DialogContent>
                </Dialog>
                {/* <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={changeStatus}
                    paragraph={`Are you sure to ${changeStatus} Industry - Department`}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                /> */}
            </div>
        </div >
    )
}

export default IndustryDepartment
