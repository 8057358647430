import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./master.scss"
import { toast, ToastContainer } from "react-toastify";
import { Checkbox, Dialog, DialogContent, TablePagination, Tooltip } from "@mui/material";
import { DataGrid, getGridSingleSelectOperators } from "@mui/x-data-grid";
import { QuestionColumns, statusCommanColumns } from "../../components/datatable-coulms/tableCoulmns";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { STATUSES } from "../../redux/common-status/constant";
import CONSTANTS from "../../components/constant/constantComponents";
import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../components/TablePaginationActions";
import { Add, AddCircleOutline, Close, Download, RemoveCircleOutline } from "@mui/icons-material";
import { InputFields, InputRadioGroupFields, InputSelectField, InputTextareaField } from "../../components/common-function/InputFields";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import { getAnswerOptions, getQuestionList, importQusetionFile, postAnswerOptions, questionArchive, questionCreate, questionEdit, questionUpdate, updateAnswerOptions, getTestTypeList, testTypeCreate, getIndustryDepartmentList, getIndustryList, getIndusDepSpecListData } from "../../redux/features/masterSlice";
import { questionLevelListOption, questionTypeListOption, technologyListOption } from "../../redux/features/optionsSlice";
import Filter from "../../components/Filter";
import LocalStorageDataService from '../../components/common-function/GetLocalstorage';
import dataService from "../../redux/services/data.service";
import CustomeTooltip from "../../components/common-function/CustomeTooltip";
import Select from 'react-select';
import CustomFilterFn from "../../components/datatable-coulms/customFilter";

const initialFormData = Object.freeze({
    title: "",
    technology: "",
    question_level: "",
    question_type: "",
    marks: "",
    images: "",
    description: "",
    for_agent: false,
    status: "",
});

const formErrData = Object.freeze({
    technologyErr: "Technology is required",
    questionlevelErr: "Experience level is required",
    questiontypeErr: "Question type is required",
    marksErr: "Mark is required",
    titleErr: "Title is required",
    descriptionErr: "Description is required",
    statusErr: "Status is required",
    answerOptionErr: "Pleas add answer option",
});

const Question = () => {
    const { REACT_APP_API_BASEURL } = process.env;
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [testOpen, setTestOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const [archiveId, setArchiveId] = useState(0)
    const [archiveOpen, setArchiveOpen] = useState(false)

    const { status, error, question_List, question_Create, question_Edit, question_Update, question_Archive,
        import_question_file, answer_options_List, industry_List, department_List, industryDepSpec_List_data, test_type_list } = useSelector(state => state.master)
    // import_question_file, answer_options_List, test_type_list } = useSelector(state => state.master)

    const [currentPage, setCurrentPage] = useState(0);
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":10}`)
    const [pageSize, setPageSize] = useState(10);
    const [limit, setLimit] = useState(10);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const { technologys, questionlevels, questiontypes } = useSelector(state => state.dropDownOption)
    const [updatedtechnologyList, setupdatedtechnologyList] = useState([])


    // custom filter
    const [fieldName, setFieldName] = useState("")
    const [defaultValue, setDefaultValue] = useState({})
    const [statusOption,setStatusOption] = useState([{ value: "True", label: 'Active' }, { value: "False", label: 'Inactive' }])

    // file uploading states
    const uploadInputRef = React.useRef(null);

    const [answerFieldActive, setAnswerFieldActive] = useState(false)
    const [answerOptionsArray, setAnswerOptionsArray] = useState([])
    const [makeAnswerOptionData, setMakeAnswerOptionData] = useState("")

    const [testTypeList, setTestTypeList] = useState([]);


    //error handling
    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setReload(false)
        }
        // eslint-disable-next-line
    }, [error])

    useEffect(() => {
        dispatch(getQuestionList(filterQuery))
        dispatch(questionLevelListOption(`{"filter": { "status": { "value": "True", "operation": "eq" } }, "order_by":{"column":"-id"},"no_limit":True}`))
        dispatch(technologyListOption(`{"filter": { "status": { "value": "True", "operation": "eq" } }, "order_by":{"column":"-id"},"no_limit":True}`))
        dispatch(questionTypeListOption())
        dispatch(getIndustryDepartmentList(`{"filter":{"status":{'value':1,"operation": "eq"}},"no_limit":"True"}`))
        dispatch(getIndustryList(`{ "filter": { "status": { "value": "True", "operation": "eq" } }, "no_limit": True }`))
        dispatch(getIndusDepSpecListData(`{"filter":{"status":{'value':"True","operation": "eq"}},"no_limit":"True"}`))


        dispatch(getTestTypeList()).then(res=>
            {
                setTestTypeList(res.payload.data.rows.map(item=>({label:item.test_type, value:item.id})))
            })

        // eslint-disable-next-line
    }, [])

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getQuestionList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getQuestionList(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(question_List?.data && question_List.data.rows && question_List.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(getQuestionList(JSON.stringify(newFilterData)))
        } else {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": '-id'
            }
            setFilterQuery(JSON.stringify(newFilterData))
        }
    }


    const getUnique2 = (arr, index) => {

        const unique = []
        test_type_list?.data?.rows?.filter(i => arr.includes(i.id))
            .map(e => unique.push(e.test_type))

        return unique;
    }

    const actionColumn = [
        {
            field: "technology_id", headerName: "Technology", width: 170, renderCell: (params) => {
                return (<div>
                    <CustomeTooltip
                        title={params.row.technology ? params.row.technology : 'N/A'}
                        data={params.row.technology ? params.row.technology : 'N/A'}
                    />
                </div>);
            },
        },
        {
            field: "question_type_id", headerName: "Question Type", width: 150, renderCell: (params) => {
                return (<div>
                    <CustomeTooltip
                        title={params.row.question_type ? params.row.question_type : 'N/A'}
                        data={params.row.question_type ? params.row.question_type : 'N/A'}
                    />
                </div>);
            },
        },
        {
            field: "question_level_id", headerName: "Experience Level", width: 190, renderCell: (params) => {
                return (<div>{ }
                    <CustomeTooltip
                        title={params.row.question_level ? params.row.question_level : 'N/A'}
                        data={params.row.question_level ? params.row.question_level : 'N/A'}
                    />
                </div>);
            },
        },
        {
            field: "test_type", headerName: "Test Types", width: 230, type: "singleSelect",
            // valueOptions: filterSpecificationList,
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">

                        <CustomeTooltip
                            title={params.row.test_type ? getUnique2(params.row.test_type, "id")?.join(",") : ""}
                            data={params.row.test_type ? getUnique2(params.row.test_type, "id")?.join(",") : "-"}
                        />
                    </div>
                );
            }
        },
    ];

    const ucActionColumn = [
        {
            field: "action", headerName: "Action", width: "90", hideable: false, filterable: false, sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <UcActionComponent
                            deleteIconName="ArchiveIcon"
                            moduleName="question-level"
                            rowid={params.row.id}
                            addMoreData=""
                            editLinkUrl=""
                            viewLinkUrl=""
                            isDelete={true}
                            editButton={handleClickEdit}
                            viewButton={false}
                            deleteButton={handleArchive}
                            addButtonTest={handleOpenTest} />
                    </div>
                );
            },
        },
    ]

    // Custom filter
    function CustomFilterInputValue(props) {
        const { item, applyValue } = props;
        const handleFilterChange = (evt) => {
            if (evt) {
                setDefaultValue(evt)
                const { value } = evt;
                applyValue({ ...item, value: value });
            }
        };
        return (
            fieldName === "technology_id" ?
                <Filter options={technologys} onChange={handleFilterChange} defaultValue={defaultValue} />
                : fieldName === "question_level_id" ?
                    <Filter options={questionlevels} onChange={handleFilterChange} defaultValue={defaultValue} />
                    : fieldName === "question_type_id" ?
                        <Filter options={questiontypes} onChange={handleFilterChange} defaultValue={defaultValue} />
                        : <Filter options={statusOption} onChange={handleFilterChange} defaultValue={defaultValue} />
        );
    }

    const columns = React.useMemo(() =>
        actionColumn.map((col) => {
            if (col.field === "technology_id" || col.field === "question_level_id" || col.field === 'question_type_id' || col.field === 'status') {
                return {
                    ...col,
                    filterOperators: getGridSingleSelectOperators()
                        .filter((operator) => operator.value !== "isAnyOf" && operator.value !== "not")
                        .map((operator) => ({
                            ...operator,
                            InputComponent: operator.InputComponent
                                ? CustomFilterInputValue
                                : undefined
                        }))
                };
            }
            return col;
        }),
        // eslint-disable-next-line
        [actionColumn]
    );

    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField) {
            setFieldName(filterModel.items[0].columnField)
        }
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                    filterModel.items[0].operatorValue === "is" || filterModel.items[0].operatorValue === 'equals' ? 'eq' :
                        filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue === 'isAnyOf' ? 'in' : filterModel.items[0].operatorValue

            const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":${JSON.stringify(filterModel.items[0].value)},"operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`;

            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getQuestionList(filterData))
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            //setPageSize(10)
            setFilterQuery(`{"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`)
            dispatch(getQuestionList(`{"order_by":{"column":"-id"},"skip":0,"limit":${limit}}`))
            setDefaultValue("")
        }
    }

    const handleOpen = () =>{ 
        setOpen(true)
        setSelectedTestType(null)
    };

    const [questionId, setQuestionId] = useState(null)
    const [selectedTestType, setSelectedTestType] = useState([])

    const handleOpenTest = (id) => {
        const data = question_List?.data?.rows?.filter((ques) => ques.id === id)[0]
        const array = []
        data?.test_type?.forEach((type) => {
            array.push(test_type_list?.data?.rows?.filter((testtype) => testtype.id === type).map((item) => {
                return {
                    label: item.test_type,
                    value: item.id,
                    name: 'test_type'
                }
            })[0])

        })
        setTestTypeIds(data.test_type)
        setSelectedTestType(array)
        setQuestionId(id)
        setTestOpen(true);

    }
    const handleCloseTest = () => { setTestOpen(false) };


    const handleClose = () => {
        if (ID) { setID(0) }
        setOpen(false);
        setFormValues(initialFormData);
        setErrStatus(false)
        setAnswerFieldActive(false)
        setAnswerOptionsArray([])
        setMakeAnswerOptionData("")
    }

    if (reload === "submit" && status === `${STATUSES.SUCCESS}_question_Update` && question_Update && question_Update.message) {
        if (question_Update.status === 200) {
            if (makeAnswerOptionData) {
                // eslint-disable-next-line
                makeAnswerOptionData.map((item) => {
                    item['question'] = question_Update.data.id
                    item['status'] = true
                    item['created_by'] = LocalStorageDataService.userDetail().id
                    const addvalues = item
                    if (item?.id) {
                        const ID = item.id;
                        dispatch(updateAnswerOptions({ ID, addvalues }))
                    } else {
                        dispatch(postAnswerOptions(item))
                    }
                })
            }
            toast.success(question_Update.message)
            dispatch(getQuestionList(filterQuery))
            handleClose()
        } else {
            toast.error(question_Update.message)
        }
        setReload(false)
    }

    if (reload === "submit" && status === `${STATUSES.SUCCESS}_question_Create` && question_Create && question_Create.message) {
        if (question_Create.status === 200) {
            if (makeAnswerOptionData) {
                // eslint-disable-next-line
                makeAnswerOptionData.map((item) => {
                    item['question'] = question_Create.data.id
                    item['status'] = true
                    item['created_by'] = LocalStorageDataService.userDetail().id
                    dispatch(postAnswerOptions(item))
                })
            }
            toast.success(question_Create.message)
            dispatch(getQuestionList(filterQuery))
            handleClose()
        } else {
            toast.error(question_Create.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_question_Archive` && question_Archive && question_Archive.message) {
        if (question_Archive.status === 200) {
            toast.success(question_Archive.message)
            dispatch(getQuestionList(filterQuery))
        } else {
            toast.error(question_Archive.message)
        }
        setReload(false)
    }

    const handleChange = (evt) => {
        const { name, value, checked } = evt.target || evt;
        if (name === 'for_agent') {
            setTimeout(() => {
                setFormValues({ ...formValues, [name]: checked });
            }, 500);

        }
        setFormValues({ ...formValues, [name]: value })
    }
    const handleChangeIndustry = (evt) => {

        const { name, value } = evt.target || evt;
        if (evt) {
            if (name === 'industry') {
                // setIndustryId(value)
                dispatch(getIndustryDepartmentList(`{"filter":{"industry_id":{"value":${value},"operation":"eq"},"status": { "value": "True", "operation": "eq" }},"no_limit":True}`))
                setFormValues({ ...formValues, [name]: evt, })
            }
            else if (name === 'department') {
                const speclist = industryDepSpec_List_data?.data?.rows.map((options) => {
                    if (evt?.value === options?.department_id) {
                        return options?.specialization_data
                    } else {
                        return [];
                    }
                }).flat()
                setupdatedtechnologyList(speclist || [])
                setFormValues({ ...formValues, [name]: evt });

            } else {
                setFormValues({ ...formValues, [name]: value });
            }

        }
    }

    const [testTypeIds, setTestTypeIds] = useState([]);

    const handleChangeTestType = (evt) => {
        const ids = [];
        console.log("evts",evt);
        evt?.forEach(element => {
            ids.push(element.value)
        });
        // setTestType(evt)
        setTestTypeIds(ids.filter(item=>item!=undefined || item!=null))
    }

    const submitData = (e) => {
        e.preventDefault()
        if (formValues.set_name === "" || formValues.technology === "" || formValues.level === "" || formValues.no_of_question === ""
            || formValues.maximum_time === "" || formValues.status === "") {
            setErrStatus(true)
        }
        if (answerFieldActive === true) {
            const isAtLeastOneCorrect = answerOptionsArray.some((option) => option.is_correct);
            if (!isAtLeastOneCorrect) {
                return toast.error("Please select at least one correct answer option");
            }
        }
        if (formValues.set_name !== "" && formValues.technology !== "" && formValues.level !== "" && formValues.no_of_question !== ""
            && formValues.maximum_time !== "" && formValues.status !== "") {
            setErrStatus(false)
            setReload("submit")

            let addvalues = ({
                ...formValues,
                title: formValues.title,
                marks: formValues.marks,
                description: formValues.description,
                status: formValues.status,
                images: formValues.images,
                technology: formValues.technology.value ? formValues.technology.value : formValues.technology,
                question_level: formValues.question_level.value ? formValues.question_level.value : formValues.question_level,
                question_type: formValues.question_type.value ? formValues.question_type.value : formValues.question_type,
                created_by: LocalStorageDataService.userDetail().id,
                industry: formValues.industry?.label ? formValues.industry.value : formValues.industry,
                department: formValues.department?.label ? formValues.department.value : formValues.department,
                test_type:testTypeIds
            })

            if (ID) {

                dispatch(questionUpdate({ ID, addvalues }))

            } else {

                dispatch(questionCreate(addvalues))

            }

            if (answerOptionsArray) {

                setMakeAnswerOptionData(answerOptionsArray)

            }
        }
    }

    const selectValueTechnologyLable = (id) => {
        let data = technologys;
        let newObject = null
        data && data.length > 0 && data.map(x => {
            return (x.id === id) && (newObject = { value: x.id, label: x.name, name: "technology" })
        })
        return newObject
    }

    const selectValueQuestionLevelLable = (id) => {
        let data = questionlevels;
        let newObject = null
        data && data.length > 0 && data.map(x => {
            return (x.id === id) && (newObject = { value: x.id, label: x.level_name, name: "question_level" })
        })
        return newObject
    }

    const selectValueQuestionTypeLable = (id) => {
        let data = questiontypes;
        let newObject = null
        data && data.length > 0 && data.map(x => {
            return (x.id === id) && (newObject = { value: x.id, label: x.question_type, name: "question_type" })
        })
        return newObject
    }

    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}` && question_Edit && question_Edit.data) {
        let editData = question_Edit.data
        setFormValues({
            technology: selectValueTechnologyLable(editData.technology_id),
            question_level: selectValueQuestionLevelLable(editData.question_level_id),
            question_type: selectValueQuestionTypeLable(editData.question_type_id),
            title: editData.title,
            description: editData.description,
            marks: editData.marks,
            for_agent: editData.for_agent,
            status: editData.status,
            images: editData.images,
            industry: { value: editData.industry?.id, label: editData.industry?.industry_name, name: "industry" },
            department: { value: editData.department?.id, label: editData.department?.name, name: "department" },
           
        })
        setTestTypeIds(editData?.test_type);
        // console.log("editData",editData.test_type.map(item=>testTypeList[item-1]))
        setSelectedTestType(editData.test_type ? editData.test_type.map((item,index)=>({label:testTypeList[item-1].label, value:testTypeList[item-1].value})) : null )
        setID(editData.id)
        setOpen(true)
        setReload(false)

        const newData = questiontypes.find((e => e.id === editData.question_type_id))
        const question_type_value = newData?.question_type_key
        let result = question_type_value && (question_type_value.match("MCQs") || question_type_value.match("mcqs") || question_type_value.match("MCQ") || question_type_value.match("mcq") || question_type_value.match("Mcq"));
        if (result) {
            dispatch(getAnswerOptions(`{"filter":{"question_id":{"value":"${editData.id}","operation":"eq"}},"no_limit":True }`))
            setAnswerFieldActive(true)
            setReload('answer_option')
        } else {
            setAnswerFieldActive(false)
        }
    }

    if (reload === "answer_option" && status === `${STATUSES.SUCCESS}_answer_options_List` && answer_options_List && answer_options_List.message) {
        if (answer_options_List.data) {
            let array = []
            answer_options_List.data.rows?.map(e => {
                return array.push({
                    id: e.id, is_correct: e.is_correct, answer_option: e.answer_option,
                    created_by: e.created_by, question: e.question_id
                })
            })
            array.length > 0 && setAnswerOptionsArray(array)
            setReload(false)
        }
    }

    const handleClickEdit = (id) => {

        dispatch(questionEdit(id))
        setReload('edit_data')
    }

    const handleArchive = (id) => {
        setArchiveId(id)
        setArchiveOpen(true)
    }

    const handleClickArchive = (id) => {
        setReload(true)
        setArchiveOpen(false)
        dispatch(questionArchive(id))
    }

    const handleQuestionTypeChange = (evt) => {
        if (evt) {
            setFormValues({ ...formValues, 'question_type': evt.value })
            if (evt.label) {
                let result = evt.label.match("MCQs") || evt.label.match("mcqs") || evt.label.match("MCQ") || evt.label.match("mcq") || evt.label.match("Mcq");
                if (result) {
                    setAnswerFieldActive(true)
                    setAnswerOptionsArray([...answerOptionsArray, {
                        answer_option: "", is_correct: false
                    }])
                } else {
                    setAnswerFieldActive(false)
                }
            }
        }
    }

    // import file code here start
    if (reload === "import_file" && import_question_file && import_question_file.status) {
        if (import_question_file.status === 200) {
            toast.success(import_question_file.message)
            dispatch(getQuestionList(filterQuery))
        } else {
            toast.error(import_question_file.message)
        }
        setReload(false)
    }

    const handleImport = (event) => {
        let file = event.target.files[0]
        //let type = file.type
        const formData = new FormData();
        formData.append("file", file);
        const fileType = file.name.split(".")[1];

        if (fileType === "xlsx") {
            setReload('import_file')
            dispatch(importQusetionFile(formData))
        } else {
            toast.error("You can only import excel file")
        }
    }
    // import file code end

    // download sapmle file code here
    const downloadSampleFile = () => {
        let url = REACT_APP_API_BASEURL + "evaluation/candidate/download/sample/2"
        window.open(url);
    }

    // upload image code 
    const onFileChange = (event) => {
        let file = event.target.files[0]
        setFormValues({ ...formValues, images: file.name });
    }

    //answer option logic
    const addAnswerOptions = () => {
        setAnswerOptionsArray([...answerOptionsArray, {
            answer_option: "", is_correct: false
        }])
    }

    const removeAnswerOptions = async (i, id = null) => {
        if (id) {
            const request = { status: 3 }
            const response = await dataService.answer_option_delete(id, request)
            if (response?.data && response.data.status === 200) {
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message);
            }
        }
        const updateformData = answerOptionsArray.filter((item, index) => index !== i);
        setAnswerOptionsArray(updateformData)
    }

    const handleAnswerOption = (evt, index = null) => {
        let { name, type, value, checked } = evt.target || evt;
        value = type === "checkbox" ? checked : value;
        const valueArray = answerOptionsArray.length > 0 && answerOptionsArray.map((item, i) => i === index ?
            { ...item, [name]: value } : item);

        if (name === 'is_correct') {
            // eslint-disable-next-line
            answerOptionsArray.length > 0 && answerOptionsArray.map((e, i) => { e[index] === i ? e['is_correct'] = true : e['is_correct'] = false })
        }
        index !== null && setAnswerOptionsArray([...valueArray])
    }

    const handleTypeOfTest = async e => {
        e.preventDefault();

        // testType
        let payload = {
            question_id: questionId,
            test_types: testTypeIds,
            "status": true,
            "created_by": LocalStorageDataService.userDetail().id,
        };

        // await apicall 
        dispatch(testTypeCreate(payload)).then(res => {
            if (res?.payload?.status === 200){
                handleCloseTest()
                dispatch(getQuestionList(filterQuery))
            } 
        })
    }

    return (
        <div className="master">
            <Sidebar />
            <div className="masterContainer">
                <Navbar />

                <ToastContainer />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Question List</h3>
                        <div>
                            <Tooltip title="Add New Question">
                                <button onClick={handleOpen} className="btn-add-new"><Add /> </button>
                            </Tooltip>
                            <input
                                id='fileUpload'
                                ref={uploadInputRef}
                                type='file'
                                multiple
                                style={{ display: "none" }}
                                onChange={handleImport}
                                name="file"
                            />
                            <Tooltip title="Import File">
                                <button
                                    onClick={() => uploadInputRef.current?.click()}
                                    className="btn-add-new">Import </button>
                            </Tooltip>
                            <Tooltip title="Download Sample file">
                                <button className="btn-add-new" onClick={downloadSampleFile}><Download /> Sample File </button>
                            </Tooltip>
                        </div>
                    </div>
                    {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') && <LoaderForm />}
                    <DataGrid
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={question_List?.data && question_List.data.rows ? question_List.data.rows : []}
                        columns={QuestionColumns.concat(columns , CustomFilterFn(statusCommanColumns),ucActionColumn)}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        onFilterModelChange={onFilterChange}
                        filterMode="server"
                        disableSelectionOnClick
                        components={{
                            Pagination: CustomPagination,
                        }}
                        onSortModelChange={(sort) => handleSort(sort)}
                    />
                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"lg"}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>{ID ? 'Edit' : 'Add New'} Question</DialogTitle>

                    <DialogContent>
                        <form>
                            <div className="formInput" style={{ width: '40%' }}>
                                <label >
                                    Select Industry
                                </label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    divstyle={{ flexBasis: '100%' }}
                                    placeholder={'Select Industry'}
                                    onChange={handleChangeIndustry}

                                    defaultValue={formValues.industry}
                                    value={formValues.industry}
                                    isSearchable={true}
                                    name={'industry'}
                                    options={
                                        industry_List?.data && industry_List.data.rows.map((option) => {

                                            return {
                                                label: option.industry_name,
                                                value: option.id,
                                                name: 'industry'
                                            }
                                        })
                                    }
                                />
                            </div>
                            {
                                formValues.industry ?
                                    <div className="formInput">

                                        <label >
                                            Select Department
                                        </label>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            // divstyle={{ flexBasis: '50%' }}
                                            placeholder={'Select Department'}
                                            name={"department"}
                                            onChange={handleChangeIndustry}
                                            defaultValue={formValues.department}
                                            value={formValues.department}
                                            isSearchable={true}
                                            options={
                                                department_List?.map((option) => {

                                                    return {
                                                        label: option.label,
                                                        value: option.value,
                                                        name: "department"
                                                    }
                                                })



                                            }
                                        />
                                    </div>
                                    : <></>
                            }
                            <div className="add-new-master-form">
                                <InputSelectField
                                    label={'Technology'}
                                    placeholder="Select Technology"
                                    defaultValue={formValues.technology}
                                    name={"technology"}
                                    onChange={handleChange}
                                    optionList={updatedtechnologyList || []}
                                    optionLevel={['name']}
                                    optionValue={"id"}
                                    errStatus={errStatus}
                                    formErr={formErr.technologyErr}
                                />
                                <InputSelectField
                                    label={'Experience Level'}
                                    placeholder="Select Experience Level"
                                    defaultValue={formValues.question_level}
                                    name={"question_level"}
                                    onChange={handleChange}
                                    optionList={questionlevels || []}
                                    optionLevel={['level_name']}
                                    optionValue={"id"}
                                    errStatus={errStatus}
                                    formErr={formErr.questionlevelErr}
                                />
                            </div>
                            <div className="formInput">
                                    <label >
                                        Type Of Test
                                    </label>
                                    <Select
                                        isMulti
                                        className="basic-single"
                                        classNamePrefix="select"
                                        divstyle={{ flexBasis: '100%' }}
                                        placeholder={'Select Type Of Test'}
                                        onChange={handleChangeTestType}
                                        defaultValue={selectedTestType}
                                        isSearchable={true}
                                        name={'test_type'}

                                        options={
                                            test_type_list?.data && test_type_list.data.rows.map((option) => {
                                                return {
                                                    label: option.test_type,
                                                    value: option.id,
                                                    name: 'test_type'
                                                }
                                            })
                                        }
                                    />
                                </div>
                            <div className="add-new-master-form">
                                <InputTextareaField
                                    label={"Title"}
                                    name="title"
                                    defaultValue={formValues.title}
                                    type="text"
                                    placeholder="Title"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.titleErr}
                                />
                            </div>
                            <div className="add-new-master-form">
                                <div className="formInput" style={{ flexBasis: "100%" }}>
                                    <InputSelectField
                                        label={'Question Type'}
                                        placeholder="Select Question Type"
                                        defaultValue={formValues.question_type}
                                        name={"question_type"}
                                        onChange={handleQuestionTypeChange}
                                        optionList={questiontypes || []}
                                        optionLevel={['question_type']}
                                        optionValue={"id"}
                                        errStatus={errStatus}
                                        formErr={formErr.questiontypeErr}
                                    />
                                </div>
                            </div>

                            <div className="add-new-master-form">
                                <div>
                                    {answerFieldActive === true &&
                                        <div className="formInput">
                                            Answer Options
                                            <Tooltip title="Add More Answer Options">
                                                <AddCircleOutline onClick={() => addAnswerOptions()} style={{ marginLeft: "5px", color: "green", cursor: "pointer" }} />
                                            </Tooltip>
                                        </div>
                                    }
                                    {answerFieldActive === true && answerOptionsArray.map((item, i) => {
                                        return (
                                            <div className="add-new-user-form" key={i} style={{ display: "flex" }}>

                                                <div className="formInput">
                                                    <label >
                                                        Answer Option
                                                    </label>
                                                    <input
                                                        name={"answer_option"}
                                                        onChange={(e) => handleAnswerOption(e, i)}
                                                        type="text"
                                                        placeholder=" Answer Option"
                                                        value={item.answer_option}
                                                    />
                                                </div>

                                                <div className="formInput" style={{ flexBasis: "43%", marginLeft: "30px" }}>
                                                    <label >
                                                        Is Correct
                                                    </label>
                                                    <div style={{
                                                        display: "flex",
                                                        gap: "20px",
                                                        alignItems: "center"
                                                    }}>
                                                        <Checkbox
                                                            className="checkbox"
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                            name={"is_correct"}
                                                            checked={item.is_correct}
                                                            onChange={(e) => handleAnswerOption(e, i)}
                                                            sx={{
                                                                '& .MuiSvgIcon-root': { fontSize: 30 }
                                                            }}
                                                        />
                                                        <div className="formInput" style={{
                                                            marginBottom: "0",
                                                            display: "flex",
                                                            alignItems: "center"
                                                        }}>
                                                            {
                                                                <Tooltip title="Remove">
                                                                    <RemoveCircleOutline style={{ color: "red", cursor: "pointer" }}
                                                                        onClick={() => removeAnswerOptions(i, item.id)}
                                                                    />
                                                                </Tooltip>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>



                                            </div>
                                        )
                                    })
                                    }
                                </div>
                            </div>

                            <div className="add-new-master-form">
                                <InputTextareaField
                                    label={"Description"}
                                    name="description"
                                    defaultValue={formValues.description}
                                    type="text"
                                    placeholder="Description"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.descriptionErr}
                                />
                                <InputFields
                                    label={"Mark"}
                                    name="marks"
                                    defaultValue={formValues.marks}
                                    type="number"
                                    placeholder="Mark"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.marksErr}
                                    min="1"
                                />
                                <div className="formInput">
                                    <label >
                                        Upload Image {ID && formValues.images ? "(" + formValues.images + ")" : ""}
                                    </label>
                                    <input
                                        name={"images"}
                                        onChange={onFileChange}
                                        type="file"
                                        placeholder="Upload Image"
                                        style={{ padding: "7px" }}
                                        accept='image/jpg, image/png,image/jpeg'
                                    />
                                </div>

                                <InputRadioGroupFields
                                    label={"Status"}
                                    name="status"
                                    defaultValue={formValues.status}
                                    type="text"
                                    placeholder="Status"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.statusErr}
                                />
                                <div className="formInput">
                                    <label >
                                        For Agent
                                    </label>
                                    <Checkbox
                                        name={"for_agent"}
                                        checked={formValues.for_agent}
                                        onChange={handleChange}
                                        defaultValue={formValues.for_agent}
                                    />
                                </div>
                            </div>
                            <button
                                className={reload === "submit" ? "submit-btn-disabled" : "submit-modal"}
                                onClick={submitData}
                                disabled={reload === "submit"}
                            >
                                {ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}
                            </button>
                        </form>
                    </DialogContent>
                </Dialog>


                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"lg"}
                    open={testOpen}
                    onClose={handleCloseTest}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleCloseTest}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>Test Type</DialogTitle>

                    <DialogContent style={{ height: "400px" }}>
                        <form onSubmit={handleTypeOfTest}>
                            <div className="add-new-master-form">
                                <div className="formInput">
                                    <label >
                                        Type Of Test
                                    </label>

                                    <Select
                                        isMulti
                                        className="basic-single"
                                        classNamePrefix="select"
                                        divstyle={{ flexBasis: '100%' }}
                                        placeholder={'Select Type Of Test'}
                                        onChange={handleChangeTestType}
                                        defaultValue={selectedTestType}
                                        isSearchable={true}
                                        name={'test_type'}

                                        options={
                                            test_type_list?.data && test_type_list.data.rows.map((option) => {
                                                return {
                                                    label: option.test_type,
                                                    value: option.id,
                                                    name: 'test_type'
                                                }
                                            })
                                        }
                                    />
                                </div>

                                <button
                                    className={reload === "submit" ? "submit-btn-disabled" : "submit-modal"}
                                    // onClick={submitData}
                                    disabled={reload === "submit"}
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </DialogContent>
                </Dialog>

                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={'Archive'}
                    paragraph={'Are you sure to archive question'}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />
            </div>
        </div >
    )
}

export default Question