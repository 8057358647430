import { Dialog, DialogContent, DialogTitle } from "@mui/material"
import Button from '@mui/material/Button';
import { Close } from "@mui/icons-material";

const LogoutModal = ({ open, onClose, heading, paragraph, handleClick }) => {
    return (
        <>
            <Dialog
                hideBackdrop
                fullWidth
                maxWidth={"xl"}
                open={open}
                onClose={onClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                PaperProps={{
                    sx: {
                        width: "25%",
                        maxHeight: 200
                    }
                }}
            >
                <Button
                    edge="start"
                    color="inherit"
                    onClick={onClose}
                    aria-label="close"
                    style={{ position: "absolute", top: "10px", right: "10px" }}>
                    <Close />
                </Button>
                <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>Confirm Logout</DialogTitle>

                <DialogContent>
                    <div className="popup-confirming">
                        <h3>{heading}</h3>
                        <p>{paragraph}</p>
                        <button className="yes" onClick={() => handleClick()}>Yes</button>
                        <button className="no" onClick={() => onClose(false)} autoFocus>
                            No
                        </button>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}
export default LogoutModal