import Navbar from "../components/navbar/Navbar";
import Sidebar from "../components/sidebar/Sidebar"
import { toast, ToastContainer } from "react-toastify";
import { Dialog, DialogContent, TablePagination, Tooltip, DialogTitle, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { STATUSES } from "../redux/common-status/constant";
import CONSTANTS from "../components/constant/constantComponents";
import * as React from 'react';
import LoaderForm from "../components/common-function/LoaderForm";
import UcActionComponent from "../components/common-function/UcActionComponent";
import TablePaginationActions from "../components/TablePaginationActions";
import { Add, ArrowBack, Close } from "@mui/icons-material";
import { InputSelectValueField } from "../components/common-function/InputFields";
import DeleteDialogModal from "../components/common-function/DeleteDialogModal";
import { createCandidateExamSchedule, getCandidateExamScheduleList, updateStatusCandidateExamSchedule } from "../redux/features/candidateExamSlice";
import { candidateListOption, scheduleListOption } from "../redux/features/optionsSlice";
import moment from "moment";
import GetLocalstorage from "../components/common-function/GetLocalstorage";
import { scheduleList } from "../redux/features/masterSlice";

const initialFormData = Object.freeze({
    candidate_id: "",
    exam_schedule: "",
});

const formErrData = Object.freeze({
    candidateErr: "Candidate is required",
    scheduleErr: "Schedule is required",
});

const CandidateExamSchdule = () => {
    const { REACT_APP_ENV } = process.env;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const [archiveId, setArchiveId] = useState(0)
    const [archiveOpen, setArchiveOpen] = useState(false)
    const { schedules, candidateName } = useSelector(state => state.dropDownOption)
    const { schedules_option } = useSelector(state => state.master)

    const { status, error, candidateExamScheduleList, candidateExamScheduleUpdate, candidateExamScheduleDelete, candidateExamScheduleCreate } = useSelector(state => state.candidate)
    const [currentPage, setCurrentPage] = useState(0);
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":10}`)
    const [pageSize, setPageSize] = useState(10);
    const [limit, setLimit] = useState(10);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });

    const [selectedSchedule, setselectedSchedule] = useState("")
    const [setId, setSetId] = useState("")
    const [selectedCandidate, setSelectedCandidate] = useState("")
    const [loading, setLoading] = useState(true)

    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    useEffect(() => {
        dispatch(getCandidateExamScheduleList(filterQuery))
        dispatch(candidateListOption())
        dispatch(scheduleList(`{"filter":{"status":{"value":"True","operation":"eq"}},"order_by": { "column": "-id" },"no_limit":True }`))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { schedule_List } = useSelector(state => state.master)

    //error handling
    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setReload(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    // clear all state 
    const cleanState = () => {
        setSelectedCandidate("")
        setselectedSchedule("")
        setStartDate(null)
        setEndDate(null)
        setSetId("")
    }

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        if (ID) { setID(0); cleanState(initialFormData) } setOpen(false); cleanState(initialFormData);;
        setErrStatus(false)
    }

    // candidate exam schedule update section
    if (reload === true && status === `${STATUSES.SUCCESS}_candidateExamScheduleUpdate` && candidateExamScheduleUpdate && candidateExamScheduleUpdate.message) {
        if (candidateExamScheduleUpdate.status === 200) {
            toast.success(candidateExamScheduleUpdate.message)
            dispatch(getCandidateExamScheduleList(filterQuery))
            handleClose()
        } else {
            toast.error(candidateExamScheduleUpdate.message)
        }
        setReload(false)
    }

    // candidate exam  schedule create section
    if (reload === true && status === `${STATUSES.SUCCESS}_candidateExamScheduleCreate` && candidateExamScheduleCreate && candidateExamScheduleCreate.message) {
        if (candidateExamScheduleCreate.status === 200) {
            toast.success(candidateExamScheduleCreate.message)
            dispatch(getCandidateExamScheduleList(filterQuery))
            handleClose()
        } else {
            toast.error(candidateExamScheduleCreate.message)
        }
        setReload(false)
    }

    // candidate exam schedule delete section
    if (reload === true && status === `${STATUSES.SUCCESS}_candidateExamScheduleDelete` && candidateExamScheduleDelete && candidateExamScheduleDelete.message) {
        if (candidateExamScheduleDelete.status === 200) {
            toast.success(candidateExamScheduleDelete.message)
            dispatch(getCandidateExamScheduleList(filterQuery))
        } else {
            toast.error(candidateExamScheduleDelete.message)
        }
        setReload(false)
    }

    const startDateAndEndDate = (id) => {
        const filteredScheduleData = schedule_List?.data?.rows.find((ele) => ele.id === id)
        if (filteredScheduleData) {
            let start_date = filteredScheduleData.start_date && moment(filteredScheduleData.start_date).format('DD-MM-YYYY')
            let end_date = filteredScheduleData.end_date && moment(filteredScheduleData.end_date).format('DD-MM-YYYY')
            setStartDate(start_date)
            setEndDate(end_date)
        }
    }

    // onchange candidate section
    const onChangeCandidate = (evt) => {
        const { value } = evt.target || evt;
        setSelectedCandidate(evt)
        setselectedSchedule("")
        dispatch(scheduleListOption(value))
        setSetId("")
    }

    // onchange schedule section
    const onChangeSchedule = (evt) => {
        const { value } = evt.target || evt;
        setselectedSchedule(evt)
        startDateAndEndDate(value)
    }

    // candidate exam schedule list section
    if (loading === true && status === `${STATUSES.SUCCESS}_candidateExamScheduleList`) {
        if (candidateExamScheduleList.status === 200) {
        } else {
            toast.error(candidateExamScheduleList.message)
        }
        setLoading(false)
    }

    // candidate exam schedule submit form data section
    const submitData = (e) => {
        e.preventDefault()
        if (selectedCandidate === "" || selectedSchedule === "") {
            setErrStatus(true)
        }

        if (selectedCandidate && selectedSchedule) {
            const ces_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_CES`))
            let userId = ces_user?.user && ces_user.user.data && ces_user.user.data.id
            let addvalues = {
                candidate: selectedCandidate.value,
                exam_schedule: selectedSchedule.value,
                created_by: userId,
                status: true,
            }
            setErrStatus(false)
            setReload(true)
            dispatch(createCandidateExamSchedule(addvalues))
        }
    }

    const handleArchive = (id) => {
        setArchiveId(id)
        setArchiveOpen(true)
    }


    const handleClickArchive = (id) => {
        setReload(true)
        setArchiveOpen(false)
        let addvalues = {
            schedule_status: 4,
        }
        dispatch(updateStatusCandidateExamSchedule({ ID: id, addvalues }))
    }

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getCandidateExamScheduleList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getCandidateExamScheduleList(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(candidateExamScheduleList && candidateExamScheduleList.data && candidateExamScheduleList.data.rows && candidateExamScheduleList.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(getCandidateExamScheduleList(JSON.stringify(newFilterData)))
        } else {
            setFilterQuery(`{ "skip": 0, "limit": 10, "order_by": { "column": "-id" } }`)
        }
    }

    //columns 
    const actionColumn = [
        {
            field: "candidate_id", headerName: "Candidate Name", width: 260, type: 'singleSelect', valueOptions: candidateName, renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        {params.row.candidate.first_name} {params.row.candidate.last_name}
                    </div>
                );
            }
        },
        {
            field: "exam_schedule_id", headerName: "Schedule Name", width: 230, type: 'singleSelect', valueOptions: schedules_option, renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        {params?.row?.exam_schedule && params.row.exam_schedule.schedule_name ? params.row.exam_schedule.schedule_name : "N/A"}
                    </div>
                );
            },
        },
        {
            field: "scheduled_date", headerName: "Scheduled Date", type: 'date',
            width: 200, renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        {moment(params.row.scheduled_date).format('DD-MM-YYYY')}
                    </div>
                );
            },
        },
        { field: "scheduled_time", headerName: "Scheduled Time", width: 200 },
        {
            field: "total_marks", headerName: "Total mark", width: 150, filterable: false, sortable: false, renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        {params.row.total_marks || params.row.schedule_status !== 7 ? params.row.total_marks : "0"}
                    </div>
                );
            },
        },
        {
            field: "marks_obtained", headerName: "Marks Obtained", width: 150, filterable: false, sortable: false, renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        {params.row.marks_obtained || params.row.schedule_status !== 7 ? params.row.marks_obtained : "0"}
                    </div>
                );
            },
        },
        {
            field: "mark_obtained_percent", headerName: "Percentage", width: 150, filterable: false, sortable: false, renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        {params.row.mark_obtained_percent || params.row.schedule_status !== 7 ? params.row.mark_obtained_percent : "0"}
                    </div>
                );
            },
        },
        {
            field: "is_passed", headerName: "Result Status", width: 190, filterable: false, sortable: false, renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}
                        style={params.row.marks_obtained && params.row.marks_obtained !== 0 || params.row.schedule_status === 7 ?
                            params.row.is_passed ? { color: "green" } : { color: "red" } : { color: "unset" }}>
                        {params.row.marks_obtained && params.row.marks_obtained !== 0 || params.row.schedule_status === 7 ?
                            params.row.is_passed ? " Pass " : " Fail " : ""}
                    </div>
                );
            },
        },
        {
            field: "schedule_status",
            headerName: "Schedule Status",
            width: 250,
            type: 'singleSelect',
            valueOptions: GetLocalstorage.schedule_exam_status_new(),
            renderCell: (params) => {
                return (
                    <div className="cellAction" style={params.row.schedule_status === 0 ? { color: 'Orange' } :
                        params.row.schedule_status === 1 ? { color: 'Orange' } :
                            params.row.schedule_status === 2 ? { color: '#14d0e4' } :
                                params.row.schedule_status === 3 ? { color: 'Orange' } :
                                    params.row.schedule_status === 4 ? { color: 'Red' } :
                                        params.row.schedule_status === 5 ? { color: 'Orange' } :
                                            params.row.schedule_status === 6 ? { color: 'Orange' } :
                                                params.row.schedule_status === 7 ? { color: 'Green' } :
                                                    { color: '#000000' }
                    }>
                        <p>{params.row.schedule_status === 0 ? "Pending" :
                            params.row.schedule_status === 1 ? "Pending" :
                                params.row.schedule_status === 2 ? "Inprogress" :
                                    params.row.schedule_status === 3 ? "Evaluation Pending" :
                                        params.row.schedule_status === 5 ? "Evaluator Assigned" :
                                            params.row.schedule_status === 6 ? "Evaluation Inprogress" :
                                                params.row.schedule_status === 7 ? "Result Declared" :
                                                    params.row.schedule_status === 4 ? "Cancelled" :
                                                        "N/A"}</p>
                    </div>
                );
            },
        },

        {
            field: "action",
            headerName: "Action",
            width: "120",
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        {(params.row.schedule_status === 0 || params.row.schedule_status === 0) ?
                            <UcActionComponent
                                deleteIconName=""
                                moduleName="candidate-exam-schedule"
                                rowid={params.row.id}
                                editLinkUrl=""
                                viewLinkUrl=""
                                isDelete={false}
                                editButton={false}
                                viewButton={false}
                                deleteButton={false}
                                extraButton={handleArchive}
                                additionalActionIcon="CancelIcon"
                                additionalAction={[{ title: "Cancel Exam", key: 'cancel' }]}
                                addMoreData={params.row.id}
                            />
                            :
                            "N/A"}
                    </div>
                );
            },
        },
    ];

    //filter
    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "is" ? 'eq' :
                    filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                        filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue === 'isAnyOf' ? 'in' : filterModel.items[0].operatorValue === 'equals' ? 'ieq' : filterModel.items[0].operatorValue

            const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":${JSON.stringify(filterModel.items[0].value)},"operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getCandidateExamScheduleList(filterData))
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            //setPageSize(10)
            setFilterQuery(`{"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`)
            dispatch(getCandidateExamScheduleList(`{"order_by":{"column":"-id"},"skip":0,"limit":${limit}}`))
        }
    }

    return (
        <div className="master">
            <Sidebar />
            <div className="masterContainer">
                <Navbar />

                <ToastContainer />
                <div className="datatable">
                    {/* heading section */}
                    <div className="top-headings">
                        <h3>Candidate Exam Schedule List</h3>
                        <div>
                            <Tooltip title="Add Candidate Exam Schedule">
                                <button onClick={handleOpen} className="btn-add-new"><Add /> </button>
                            </Tooltip>
                            <Tooltip title="Back">
                                <Link to={-1} className="btn-add-new"><ArrowBack /> </Link>
                            </Tooltip>
                        </div>
                    </div>
                    {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') && <LoaderForm />}

                    {/*table list section */}
                    <DataGrid
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={candidateExamScheduleList?.data && candidateExamScheduleList.data.rows ? candidateExamScheduleList.data.rows : []}
                        columns={actionColumn}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        onFilterModelChange={onFilterChange}
                        disableSelectionOnClick
                        components={{
                            Pagination: CustomPagination,
                        }}
                        onSortModelChange={(sort) => handleSort(sort)}
                    />
                </div>

                {/* add candidate exam schedule form  */}
                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>{'Add'} Candidate Exam Schedule</DialogTitle>

                    <DialogContent style={{ minHeight: "400px" }}>
                        <form>
                            <div className="add-new-master-form">
                                <InputSelectValueField
                                    label={'Candidate'}
                                    placeholder="Select Candidate"
                                    defaultValue={selectedCandidate}
                                    name={"candidate_id"}
                                    onChange={onChangeCandidate}
                                    optionList={candidateName || []}
                                    optionLevel={['first_name', 'email']}
                                    optionValue={"id"}
                                    errStatus={errStatus}
                                    formErr={formErr.candidateErr}
                                />

                                <InputSelectValueField
                                    label={'Schedule'}
                                    placeholder="Select Schedule"
                                    defaultValue={selectedSchedule}
                                    name={"schedule_id"}
                                    onChange={onChangeSchedule}
                                    optionList={schedule_List?.data?.rows || []}
                                    optionLevel={['schedule_name']}
                                    optionValue={"id"}
                                    errStatus={selectedCandidate && schedule_List && schedule_List?.data?.rows.length === 0 ? true : errStatus} formErr={selectedCandidate && schedules && schedules.length === 0 ? 'Schedule not available for this candidate' : formErr.scheduleErr}
                                />

                            </div>
                            {selectedSchedule ?
                                <div className="add-new-master-form">
                                    <div className="formInput">
                                        <label style={{ marginBottom: "15px", fontWeight: 600 }}>Start Date : &nbsp; </label>
                                        {startDate ? startDate : " N/A"}<br />
                                        <label style={{ fontWeight: 600 }}>End Date :  &nbsp; </label>
                                        {endDate ? endDate : " N/A"}
                                    </div>
                                </div> : ""
                            }

                            <button
                                className={reload === true ? "submit-btn-disabled" : "submit-modal"}
                                disabled={reload === true ? true : false}
                                onClick={submitData}>
                                {ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}
                            </button>
                        </form>
                    </DialogContent>
                </Dialog>

                {/* delete modal */}
                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={'Cancel Candidate Exam'}
                    paragraph={'Are you sure to cancel candidate exam'}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />
            </div>
        </div >
    )
}

export default CandidateExamSchdule