import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./profile.scss";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LoaderForm from "../../components/common-function/LoaderForm";
import { candidateCreate, candidateUpdateById, candidateUpdateByIdFile } from "../../redux/features/resourceSlice";
import "react-datepicker/dist/react-datepicker.css";
import DataService from "../../redux/services/data.service";
import { toast, ToastContainer } from "react-toastify";
import CONSTANTS from "../../components/constant/constantComponents";
import { STATUSES } from "../../redux/common-status/constant";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import { roleListOption } from "../../redux/features/optionsSlice";
import Dropzone, { useDropzone } from 'react-dropzone';
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';
import { RemoveCircleOutline } from '@mui/icons-material';
import Multiselect from 'multiselect-react-dropdown';
import { getQualificationList, getTechnologyList, getIndustryList, getIndustryDepartmentList, getIndusDepSpecListData } from "../../redux/features/masterSlice";
import { getDepartmentList } from "../../../src/redux/features/departmentSlice";
import Select from 'react-select';
import { FormControl, FormControlLabel, FormLabel, RadioGroup, Radio, Tooltip } from '@mui/material';

let user = GetLocalstorage.userDetail();
let tmsRole = user && user.tms_role
const { REACT_APP_API_BASEURL } = process.env;


const initialFormData = Object.freeze({
    first_name: "",
    last_name: "",
    email: "",
    contact_no: "",
    tms_role: "",
    password: "",
    technologies: "",
    is_fresher: ""
});

const formErrData = Object.freeze({
    f_nameErr: "First name is required",
    l_nameErr: "Last name is required",
    emailErr: 'Email required',
    contact_noErr: "Contact no is required",
    tms_roleErr: "Role is required",
    passwordErr: "Password is required",
    skillErr: "Skill is required",
    answerErr: "Please select answer",
    totalExErr: "Total experience is required",
    departmentErr: "Department is required",
    industryErr: "Industry is required",
    technologyErr: "Technology is required"

});

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};

const options = [
    { value: 'votar_id', label: 'Votar ID' },
    { value: 'aadhar_card', label: 'Aadhar ID' },
    { value: 'dl', label: 'Driving Licence' },
    { value: 'pan', label: 'PAN' }

]

const ProfileEdit = ({ canId, title }) => {

    const dispatch = useDispatch()
    const { Id } = useParams()
    const location = useLocation()
    // eslint-disable-next-line
    let candidateId = location.pathname === "/candidate/add" ? null : location.state && location.state.id || Id || canId || user.id;

    const { REACT_APP_ENV } = process.env;
    const navigate = useNavigate()
    const { roles_candidate } = useSelector(state => state.dropDownOption)
    const { view_data, status, error, updateCandidate, upload_candidate_file, createCandidate } = useSelector(state => state.resource)
    const { qualification_List, industry_List, department_List, industryDepSpec_List_data } = useSelector(state => state.master)
    const [formValues, setFormValues] = useState(initialFormData)
    const [loader, setLoader] = useState(false)
    const [formErr] = useState(formErrData)
    const [errSatus, setErrSatus] = useState(false);
    const [validateErr, setValidateErr] = useState("")
    const [selectedImage, setSelectedImage] = useState([]);
    const [files, setFiles] = useState({});
    const [technologies, setTechnologies] = useState([])
    const [, setRoleName] = useState("")
    const [imageStatus, setImageStatus] = useState(false)
    const [showImage, setShowImage] = useState("")
    const [showUploadeFile, setShowUploadeFile] = useState("")
    const [attchedFileType, setAttchedFileType] = useState([])
    const [candidateTechnologies, setCandidateTechnologies] = useState([])

    const [showTotalExperience, setShowTotalExperience] = useState(false);
    const [totalExperienceYears, setTotalExperienceYears] = useState(0)
    const [totalExperienceMonths, setTotalExperienceMonths] = useState(0)
    const [updatedtechnologyList, setupdatedtechnologyList] = useState([])


    const [selectedBatch, setSelectedBatch] = useState([])
    const [batchOptions, setBatchOptions] = useState([])
    const [filterQuery,] = useState({
        technology_ids: []
    }
    )


    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': []
        },
        onDrop: acceptedFiles => {
            setSelectedImage(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    });

    const loadData = async () => {
        const res = await DataService.candidate_view(candidateId)
        if (res && res.data && res.data.data) {
            const editData = res.data.data
            let qualificationArray = []
            if (editData && editData.candidate_qualification) {
                editData.candidate_qualification.map(e => {
                    qualificationArray.push({
                        id: e.qualification_id,
                        qualification_name: e.qualification
                    })
                    return qualificationArray
                })

            }
            let batchArray = []
            if (editData && editData.batch) {
                editData.batch.map(e => {
                    batchArray.push({
                        value: e.id,
                        label: e.name
                    })
                    return batchArray
                })
                setSelectedBatch(batchArray)
            }

            let array = []
            if (editData && editData.candidate_technologies) {

                editData.candidate_technologies.map(e => {
                    array.push({
                        update_id: e.id,
                        id: e.technology_id,
                        technology: e.technology_name,
                        technology_id: e.technology_id,
                        experience_years: e.experience_years,
                        experience_month: e.experience_month,
                        status: true,
                        name: e.technology_name,
                    })
                    return array
                })
                if (array.length === 0) {
                    // window.history.pushState(null, null, window.location.href);
                    // window.onpopstate = function () {
                    //     window.history.go(0);
                    // };
                }
                setCandidateTechnologies(array)
                setTechnologies(array)
            }
            if (editData && editData.candidate_profile_image) {
                setShowImage(editData.candidate_profile_image[0])
            }
            if (editData && editData.candidate_documents) {
                let array = []
                let array2 = []
                editData.candidate_documents.map(e => {
                    let filterOption = options.filter(x => x.value === e.display_name)
                    if (filterOption.length > 0) {
                        array2.push(filterOption[0])
                    }
                    if (e.display_name === 'votar_id' || e.display_name === 'aadhar_card' || e.display_name === 'dl' || e.display_name === 'pan') {
                        array.push(e)
                    }
                    return array

                })


                if (array2.length > 0) {
                    const unique2 = array2.filter((obj, index) => {
                        return index === array2.findIndex(o => obj.id === o.id && obj.value === o.value);
                    });
                    setAttchedFileType(unique2)
                }
                setShowUploadeFile(array)
            }

            setFormValues({
                first_name: editData && editData.first_name,
                last_name: editData && editData.last_name,
                email: editData && editData.email,
                contact_no: editData && editData.contact_no,
                tms_role: editData && editData.tms_role_id,
                password: editData && editData.password,
                user: editData && editData.user_id,
                technologies: array,
                qualification: qualificationArray,
                is_fresher: editData && editData.is_fresher,
                address: editData && editData.address,
                batch: batchArray,
                // industry:  editData && editData.industry['industry_name'],
                // department:editData && editData.department.id,
                department: { name: 'department', label: editData?.department?.name, value: editData?.department?.id },
                industry: { name: 'industry', label: editData?.industry?.industry_name, value: editData?.industry?.id }


            })
            if (editData && editData.is_fresher === false) {
                setShowTotalExperience(true)
                setTotalExperienceYears(editData.experience_years ? editData.experience_years : 0)
                setTotalExperienceMonths(editData.experience_months ? editData.experience_months : 0)
            }

            setRoleName(editData && editData.tms_role && editData.tms_role.role_key)
        }

    }

    const handleBatchChange = (selectedOption) => {

        setSelectedBatch(selectedOption)
    }


    useEffect(() => {
        if (candidateId) {
            loadData()
        }
        dispatch(roleListOption())
        dispatch(getTechnologyList(`{"filter":{"status":{"value":"True","operation":"eq"}},"order_by":{"column":"-id"},"columns":["id","name"], "no_limit":True}`))
        dispatch(getQualificationList(`{"filter":{"status":{"value":"True","operation":"eq"}},"order_by":{"column":"-id"},"columns":["id","qualification_name"], "no_limit":True}`))
        dispatch(getIndustryList(`{ "filter": { "status": { "value": "True", "operation": "eq" } }, "no_limit": True }`))
        dispatch(getIndustryDepartmentList(`{"filter":{"status":{'value':1,"operation": "eq"}},"no_limit":"True"}`))
        dispatch(getIndusDepSpecListData(`{"filter":{"status":{'value':"True","operation": "eq"}},"no_limit":"True"}`))


        dispatch(getDepartmentList())


        if (view_data && view_data.data) {
            setFormValues(loadEditResourceData)
        }
        // eslint-disable-next-line
    }, [dispatch])

    useEffect(() => {
        if (industryDepSpec_List_data?.data?.rows && industryDepSpec_List_data?.data?.rows.length > 0) {
            let speclist = industryDepSpec_List_data?.data?.rows.map((options) => {
                if (formValues?.department?.value === options?.department_id) {
                    return options?.specialization_data
                } else {
                    return [];
                }
            }).flat()
            setupdatedtechnologyList(speclist || [])
        }
    }, [industryDepSpec_List_data]);

    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        setFormValues({ ...formValues, [name]: value });
    }

    const handleChangefresher = (evt) => {
        const { value } = evt.target || evt;
        setTotalExperienceYears(0)
        setTotalExperienceMonths(0)
        if (value === 'true') {
            setShowTotalExperience(false)
            setFormValues({ ...formValues, is_fresher: true });
        } else {
            setShowTotalExperience(true)
            setFormValues({ ...formValues, is_fresher: false });
        }
    }

    const validate = (email, contact) => {
        const errors = {}

        if (!email) {
            errors.email = 'Required'
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            errors.email = 'Invalid email address'
        } else {
            errors.email = true
        }

        let pattern = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);
        if (!pattern.test(contact)) {
            errors.contact = "Please Enter Number Only";
        } else if (contact.length !== 10 ) {
            errors.contact = "Please enter valid  Mobile Number.";
        }else {
            errors.contact = true
        }

        return errors
    }

    const getFormData = (obj) => {
        const formData = new FormData();
        Object.keys(obj).forEach(key => formData.append(key, obj[key]));

        return formData;
    }
    const handleChangeIndustry = (evt) => {
        const { name, value } = evt.target || evt;
        if (evt) {
            if (name === 'industry') {
                dispatch(getIndustryDepartmentList(`{"filter":{"industry_id":{"value":${value},"operation":"eq"}},"no_limit":True}`))
                setFormValues({ ...formValues, [name]: evt, "department": "", "technologies": "" })
                setupdatedtechnologyList([])
                setSelectedBatch([])
                setTechnologies([])
            } else if (name === 'department') {
                const speclist = industryDepSpec_List_data?.data?.rows.map((options) => {
                    if (evt?.value === options?.department_id) {
                        return options?.specialization_data
                    } else {
                        return [];
                    }
                }).flat()
                setupdatedtechnologyList(speclist || [])
                setFormValues({ ...formValues, [name]: evt, "technologies": "" });
                setSelectedBatch([])
                setTechnologies([])
            } else {
                setFormValues({ ...formValues, [name]: value });
                setSelectedBatch([])
                setTechnologies([])
            }
        }
    }

    const submitData = (e) => {
        e.preventDefault()
        if (formValues.first_name && formValues.last_name && formValues.email && formValues.password && formValues.contact_no && formValues.technologies && formValues.technologies.length > 0 && formValues.is_fresher !== null && formValues.industry !== "" && formValues.department !== "") {
            const isValidate = validate(formValues.email, formValues.contact_no)
            let qualification = []
            if (formValues.qualification) { formValues.qualification.filter(x => qualification.push(x.id)) }
            const finalTotalExperienceMonth = totalExperienceYears * 12 + Number(totalExperienceMonths)

            if (isValidate.email === true && isValidate.contact === true) {
                const batches = []
                selectedBatch.map(option => {
                    batches.push(option.value)
                })
                let addvalues = {
                    ...formValues,
                    first_name: formValues.first_name,
                    last_name: formValues.last_name,
                    qualification: qualification,
                    status: true,
                    technologies: technologies,
                    experience_years: Number(totalExperienceYears),
                    experience_months: Number(totalExperienceMonths),
                    is_fresher: formValues.is_fresher,
                    batch: batches,
                    industry: formValues.industry && formValues.industry.label ? formValues.industry.value : formValues.industry,
                    department: formValues.department && formValues.department.label ? formValues.department.value : formValues.department,
                }
                setErrSatus(false)
                setValidateErr("")
                setLoader(true)
                let skillTotalMonth;

                let yearExperience = 0;
                let monthExperience = 0;
                // eslint-disable-next-line
                technologies && technologies.length > 0 && technologies.map((item) => {
                    if (item.experience_years) {
                        yearExperience = Number(item.experience_years)
                        skillTotalMonth = yearExperience * 12
                        if (finalTotalExperienceMonth < skillTotalMonth) {
                            setLoader(false)
                            toast.error(`Your total experience is ${totalExperienceYears} years ${totalExperienceMonths !== 0 ? totalExperienceMonths + " months" : ""} 
                            so please enter valid ${item.technology} experience`)
                        }
                    }
                    if (item.experience_month) {
                        monthExperience = Number(item.experience_month)
                        if (finalTotalExperienceMonth < monthExperience) {
                            setLoader(false)
                            toast.error(`Your total experience is ${totalExperienceYears} years ${totalExperienceMonths !== 0 ? totalExperienceMonths + " months" : ""}  
                            so please enter valid ${item.technology} experience`)
                        }
                    }
                })

                if (finalTotalExperienceMonth === skillTotalMonth || finalTotalExperienceMonth > skillTotalMonth) {

                    if (candidateId) {
                        dispatch(candidateUpdateById({ id: candidateId, addvalues: addvalues }))
                        if (selectedImage.length > 0) {
                            let obj = {
                                id: candidateId,
                                file: selectedImage[0],
                                display_name: selectedImage[0].name.split(".")[0],
                                upload_type: 'candidate_image'
                            }
                            setImageStatus('upload_image')
                            dispatch(candidateUpdateByIdFile(getFormData(obj)))

                        }
                        if (attchedFileType.length > 0) {
                            // eslint-disable-next-line
                            attchedFileType.map(item => {
                                if (files[item.value]) {
                                    let obj = {
                                        id: candidateId,
                                        file: files[item.value].file[0],
                                        display_name: item.value,
                                        upload_type: 'candidate_document'
                                    }
                                    setImageStatus('upload_image')
                                    dispatch(candidateUpdateByIdFile(getFormData(obj)))
                                }

                            })
                        }
                    } else {
                        const createNewUser = async () => {
                            const resp = await dispatch(candidateCreate({
                                ...addvalues, tms_role: roles_candidate && roles_candidate.length > 0 && roles_candidate[0].value,
                                registration_status: 0,
                                created_by: GetLocalstorage.userDetail().id, resume_file: null
                            }))
                            candidateId = resp.payload?.data?.id
                            if (candidateId !== null) {
                                if (selectedImage.length > 0) {
                                    let obj = {
                                        id: candidateId,
                                        file: selectedImage[0],
                                        display_name: selectedImage[0].name.split(".")[0],
                                        upload_type: 'candidate_image'
                                    }
                                    setImageStatus('upload_image')
                                    dispatch(candidateUpdateByIdFile(getFormData(obj)))

                                }
                                if (attchedFileType.length > 0) {
                                    // eslint-disable-next-line
                                    attchedFileType.map(item => {
                                        if (files[item.value]) {
                                            let obj = {
                                                id: candidateId,
                                                file: files[item.value].file[0],
                                                display_name: item.value,
                                                upload_type: 'candidate_document'
                                            }
                                            setImageStatus('upload_image')
                                            dispatch(candidateUpdateByIdFile(getFormData(obj)))
                                        }

                                    })
                                }
                            }

                        }
                        createNewUser()
                    }
                }
                //update Local Storage
                let userdetail = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_CES`))
                if (userdetail?.user?.data?.id && candidateId === userdetail.user.data.id && userdetail?.user?.data?.first_name && formValues?.first_name && formValues.first_name !== userdetail.user.data.first_name) {
                    userdetail.user.data.first_name = formValues.first_name
                    localStorage.setItem(`${REACT_APP_ENV}_CES`, JSON.stringify(userdetail))
                }

            } else {
                setErrSatus(true)
                setValidateErr(isValidate)
            }

        } else {
            setErrSatus(true)
        }
    }

    if (imageStatus !== false && upload_candidate_file) {
        if ((imageStatus === 'upload_resume' || imageStatus === 'upload_image') && upload_candidate_file.status === 200) {
            toast.success(upload_candidate_file.message)
        } else {
            toast.error(upload_candidate_file.message)
        }
        setImageStatus(false)
    }

    if (loader === true && status === STATUSES.FAILURE && error && error.message) {
        setLoader(false)
        toast.error(error.message);
    }

    if (loader === true && status === `${STATUSES.SUCCESS}_updateCandidate` && updateCandidate && updateCandidate.message) {
        if (updateCandidate.status === 200) {
            toast.success(updateCandidate.message);
            setTimeout(() => {
                // navigate('/candidate')
                 navigate(-1)
                setLoader(false)
            }, 2000)
        } else {
            toast.error(updateCandidate.message)
            setLoader(false)
        }
        // setLoader(false)
    }
    if (loader === true && status === `${STATUSES.SUCCESS}_createCandidate` && createCandidate && createCandidate.message) {
        if (createCandidate.status === 200) {
            toast.success(createCandidate.message);
            setTimeout(() => {
                // navigate('/candidate')
                 //navigate(-1)
                setLoader(false)
            }, 2000)
        } else {
            toast.error(createCandidate.message)
            setLoader(false)
        }
        // setLoader(false)
    }

    const goback = () => {
        // if (candidateId) {
        //     navigate('/profile')
        // } else {
        // navigate(-1)

        navigate('/candidate')
        //}
    }

    const loadEditResourceData = () => {
        const data = view_data.data
        const editFormValues = {
            first_name: data && data.first_name,
            last_name: data && data.last_name,
            email: data && data.email,
            contact_no: data && data.contact_no,
            password: data && data.password

        }
        return editFormValues
    }


    const addMoreSkill = (evt) => {
        let array = []
        setFormValues({ ...formValues, technologies: evt })
        evt.map(e => {
            let filterExp = technologies.filter(x => x.technology_id === e.id)
            return array.push({
                id: e.update_id ? e.update_id : "",
                technology: e.name,
                technology_id: e.id,
                experience_years: filterExp.length > 0 ? filterExp[0].experience_years : "0",
                experience_month: filterExp.length > 0 ? filterExp[0].experience_month : "0",
                status: true
            })
        })

        if (evt?.length === 0) {
            setSelectedBatch([])
        }

        setTimeout(() => {
            setTechnologies(array)
        }, 500);

    }

    const removeSkill = (i) => {
        const updateformData = technologies.filter((item, index) => index !== i);
        const updateFormValues = formValues && formValues.technologies.filter((item, index) => index !== i);
        setFormValues({ ...formValues, technologies: updateFormValues })
        setTechnologies(updateformData)
    }

    const handleSkillChange = (evt, index = null) => {
        const { name, value } = evt.target || evt;
        const valueArray = technologies.length > 0 && technologies.map((item, i) => i === index ?
            { ...item, [name]: value } : item)
        index !== null && setTechnologies([...valueArray])
    }

    const addMoreQualification = (evt) => {
        setFormValues({ ...formValues, qualification: evt })

    }

    const thumbs = selectedImage.map(file => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img
                    src={file.preview}
                    style={img}
                    alt="not found"
                    // Revoke data uri after image is loaded
                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                />
            </div>
        </div>
    ));

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => selectedImage.forEach(file => URL.revokeObjectURL(file.preview));
    }, [selectedImage]);

    const onDrop = (acceptedFiles, item) => {
        if (acceptedFiles[0].type === "image/jpeg" || acceptedFiles[0].type === "image/png" || acceptedFiles[0].type === "image/jpg") {
            setFiles({
                ...files, [item.value]: {
                    display_name: item.label, file: acceptedFiles.map(file => Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    }))
                }
            })
        } else {
            toast.warning("Only *.jpeg and *.png images will be accepted")
        }

    };

    const removeFiles = (value) => {
        let copyFiles = { ...files };
        delete copyFiles[value];
        setFiles(copyFiles);
    }
    let experienceYearArr = [];
    let experienceMonthArr = [];

    for (let i = 0; i <= 50; i++) {
        experienceYearArr.push(i);
    }

    for (let i = 0; i <= 11; i++) {
        experienceMonthArr.push(i);
    }

    useEffect(() => {
        const loadBatch = async () => {
            filterQuery.technology_ids = [...technologies.map(obj => obj.technology_id)]
            const batchdata = await DataService.batch_by_tech(filterQuery)
            let newArray = []
            batchdata?.data?.data?.batch_data?.map(row => {
                newArray.push({
                    value: row.batch_id,
                    label: row.batch_name
                })

            })

            setBatchOptions(newArray)
        }
        loadBatch()
    }, [technologies.length])

    return (
        <div className="resource">
            {(tmsRole && tmsRole.role_key === 'candidate' && (candidateTechnologies && candidateTechnologies.length === 0)) ? "" : <Sidebar />}
            <div className="resourceContainer">
                <Navbar activeCandidate={!!((tmsRole && tmsRole.role_key === 'candidate' && (candidateTechnologies && candidateTechnologies.length === 0)))} />
                <div className="top-headings">
                    <h3>{title}</h3>
                    <Tooltip title="Back">
                        <button onClick={goback} className="btn btn-add-new"><ArrowBackIcon /></button>
                    </Tooltip>
                </div>
                <ToastContainer />
                {loader === true && <LoaderForm />}
                <div>
                    <form>
                        <div className="add-new-user-form">
                            <div className="formInput">
                                <label >
                                    First Name
                                </label>
                                <input
                                    name="first_name"
                                    defaultValue={formValues.first_name}
                                    type="text"
                                    placeholder="First Name"
                                    onChange={handleChange}
                                />
                                {errSatus === true && formValues && formValues.first_name === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.f_nameErr}</p>}
                            </div>
                            <div className="formInput">
                                <label >
                                    Last Name
                                </label>
                                <input
                                    name="last_name"
                                    defaultValue={formValues.last_name}
                                    type="text"
                                    placeholder="Last Name"
                                    onChange={handleChange}
                                />
                                {errSatus === true && formValues && formValues.last_name === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.l_nameErr}</p>}
                            </div>
                            <div className="formInput">
                                <label >
                                    Email
                                </label>
                                <input
                                    name="email"
                                    type="email"
                                    defaultValue={formValues.email}
                                    placeholder="Email"
                                    onChange={handleChange}
                                />
                                {errSatus === true && formValues && formValues.email === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.emailErr}</p>}
                                {errSatus === true && validateErr && validateErr.email !== true && <p style={{ color: "#96332c", fontSize: 13 }}>{validateErr && validateErr.email}</p>}
                            </div>

                            <div className="formInput">
                                <label >
                                    Contact No
                                </label>
                                <input
                                    name="contact_no"
                                    defaultValue={formValues.contact_no}
                                    type="text"
                                    placeholder="Contact No"
                                    onChange={handleChange}
                                    onMouseMove={handleChange}
                                />
                                {/* {errSatus === true && formValues && formValues.contact_no === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.contact_noErr}</p>}
                                {errSatus === true && validateErr && validateErr.contact !== true && <p style={{ color: "#96332c", fontSize: 13 }}>{validateErr && validateErr.contact}</p>} */}
                                 {errSatus === true && formValues && formValues.contact_no === "" && !!(validateErr?.contact) !== true && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.contact_noErr}</p>}
                                {errSatus === true && validateErr && validateErr.contact !== true &&  <p style={{ color: "#96332c", fontSize: 13 }}>{validateErr && validateErr.contact}</p>}
                            </div>
                            <div className="formInput">
                                <label >
                                    Select Industry
                                </label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    divstyle={{ flexBasis: '100%' }}
                                    placeholder={'Select Industry'}
                                    onChange={handleChangeIndustry}

                                    defaultValue={formValues.industry}
                                    value={formValues.industry}
                                    isSearchable={true}
                                    name={'industry'}
                                    options={
                                        industry_List && industry_List.data && industry_List.data.rows.map((option) => {
                                            return {
                                                label: option.industry_name,
                                                value: option.id,
                                                name: 'industry'
                                            }
                                        }).reverse()
                                    }
                                />
                                {errSatus === true && formValues && formValues.industry === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.industryErr}</p>}
                            </div>

                            {
                                formValues.industry ?
                                    <div className="formInput">

                                        <label >
                                            Select Department
                                        </label>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            // divstyle={{ flexBasis: '50%' }}
                                            placeholder={'Select Department'}
                                            name={"department"}
                                            onChange={handleChangeIndustry}
                                            defaultValue={formValues.department}
                                            value={formValues.department}
                                            isSearchable={true}
                                            options={
                                                department_List && department_List.map((option) => {

                                                    return {
                                                        label: option.label,
                                                        value: option.value,
                                                        name: "department"
                                                    }
                                                })



                                            }
                                        />
                                        {errSatus === true && formValues && formValues.department === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.departmentErr}</p>}
                                    </div>
                                    : <></>
                            }


                            <div className="formInput">
                                <label >
                                    Password
                                </label>

                                <input
                                    name="password"
                                    defaultValue={formValues.password}
                                    type="password"
                                    placeholder="password"
                                    onChange={handleChange}
                                />
                                {errSatus === true && formValues && formValues.password === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.passwordErr}</p>}

                            </div>
                            <div className="formInput" >
                                <label >
                                    Qualification
                                </label>
                                <Multiselect
                                    isObject={true}
                                    onRemove={addMoreQualification}
                                    onSelect={addMoreQualification}
                                    selectedValues={formValues.qualification}
                                    displayValue="qualification_name"
                                    options={(qualification_List && qualification_List.data && qualification_List.data.rows ? qualification_List.data.rows : [])}
                                />
                            </div>

                            <div className="formInput">
                                <label >
                                    Profile Photo
                                </label>

                                <section style={{
                                    minWidth: '100%',
                                    padding: '1px 10px',
                                    borderRadius: 4,
                                    border: '1px solid #42526e',
                                    boxSizing: 'border-box',
                                    backgroundColor: '#fff'
                                }}>
                                    <div {...getRootProps({ className: 'dropzone' })}>
                                        <input {...getInputProps()} />
                                        <p>{selectedImage && selectedImage.length > 0 ? selectedImage[0].name : "Drag 'n' drop some image here, or click to select image"}  </p>
                                    </div>
                                    {showImage && selectedImage && selectedImage.length === 0 ?
                                        <aside style={thumbsContainer}>
                                            <div style={thumb}>
                                                <div style={thumbInner}>
                                                    <img
                                                        src={(REACT_APP_API_BASEURL).replace("api/", "api") + showImage.api_path_for_download}
                                                        style={img}
                                                        alt="not found"
                                                    />
                                                </div>
                                            </div>
                                        </aside>
                                        :
                                        <aside style={thumbsContainer}>
                                            {thumbs}  {selectedImage && selectedImage.length > 0 && <HighlightOffTwoToneIcon onClick={(i) => removeFiles(i)} />}
                                        </aside>}
                                </section>
                            </div>
                            <div className="formInput">
                                <FormControl>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Are You A Fresher ?</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="is_fresher"
                                        value={formValues.is_fresher}
                                        onChange={handleChangefresher}
                                    >
                                        <FormControlLabel value={"true"} control={<Radio />} label="Yes" />
                                        <FormControlLabel value={"false"} control={<Radio />} label="No" />
                                    </RadioGroup>
                                    {showTotalExperience !== true && errSatus === true && (formValues.is_fresher === "" || formValues.is_fresher === null) && <p style={{ color: "#96332c", fontSize: 13 }}>{formErrData.answerErr}</p>}
                                </FormControl>
                            </div>
                            {
                                showTotalExperience === true ?
                                    <>
                                        <div className="formInput" style={{ flexBasis: "100%" }} >
                                            Total Experience
                                        </div>
                                        <div className="formInput">
                                            <label>Total Years Of Experience</label>
                                            <select
                                                name={"total_experience_in_years"}
                                                onChange={(evt) => setTotalExperienceYears(Number(evt.target.value))}
                                                value={totalExperienceYears}
                                            >
                                                {experienceYearArr.map((e, i) => {
                                                    return (<option value={e} key={String(i+'p')}>{e}</option>)
                                                })}
                                            </select>
                                        </div>

                                        <div className="formInput">
                                            <label>Total Motns Of Experience</label>
                                            <select
                                                name={"total_experience_in_months"}
                                                onChange={(evt) => setTotalExperienceMonths(Number(evt.target.value))}
                                                value={totalExperienceMonths}
                                            >
                                                {experienceMonthArr.map((e, i) => {
                                                    return (<option value={e} key={String(i+'m')}>{e}</option>)
                                                })}
                                            </select>
                                        </div>
                                    </>
                                    : ""
                            }
                            <div className="formInput" style={{ flexBasis: "65%" }}>
                                <label >
                                    Technology
                                </label>
                                <Multiselect
                                    isObject={true}
                                    onRemove={addMoreSkill}
                                    onSelect={addMoreSkill}
                                    selectedValues={formValues.technologies}
                                    displayValue="name"
                                    // options={(technology_List && technology_List.data && technology_List.data.rows ? technology_List.data.rows : [])}
                                    options={updatedtechnologyList || []}

                                />
                                {errSatus === true && (technologies === "" || technologies.length === 0) && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.technologyErr}</p>}
                            </div>
                            {
                                technologies.map((item, i) => {
                                    return (
                                        <div className="add-new-user-form" style={{ width: "100%", alignItems: "center" }} key={String(i+'l')}>

                                            <div className="formInput" style={{ flexBasis: '40%' }}>
                                                <label >
                                                    Skill
                                                </label>
                                                <input
                                                    disabled
                                                    name={"technology"}
                                                    onChange={(e) => handleSkillChange(e, i)}
                                                    type="text"
                                                    placeholder="Skill"
                                                    value={item.technology}
                                                />
                                            </div>

                                            <div className="formInput" style={{ flexBasis: '20%' }}>
                                                <label >
                                                    Experienced of years
                                                </label>
                                                <select
                                                    name={"experience_years"}
                                                    onChange={(e) => handleSkillChange(e, i)}
                                                    value={item.experience_years}
                                                >
                                                    {experienceYearArr.map((e, i) => {
                                                        return (<option value={e} key={String(i+'k')}>{e}</option>)
                                                    })}
                                                </select>
                                            </div>

                                            <div className="formInput" style={{ flexBasis: '20%' }}>
                                                <label >
                                                    Experienced of months
                                                </label>
                                                <select
                                                    name={"experience_month"}
                                                    onChange={(e) => handleSkillChange(e, i)}
                                                    value={item.experience_month}
                                                >
                                                    {experienceMonthArr.map((e, i) => {
                                                        return (<option value={e} key={String(i+'j')}>{e}</option>)
                                                    })}
                                                </select>
                                            </div>

                                            <div className="formInput" style={{ flexBasis: '8%', marginTop: '3%' }}>
                                                <RemoveCircleOutline style={{ color: "red", cursor: "pointer" }} onClick={() => removeSkill(i)} />
                                            </div>

                                        </div>
                                    )
                                })

                            }
                            {
                                technologies.length !== 0 && (
                                    <>
                                        <div className="formInput" style={{ flexBasis: "65%" }}>
                                            <label >
                                                Batch
                                            </label>
                                            <Select
                                                value={selectedBatch}
                                                isMulti
                                                // name=""
                                                options={batchOptions}
                                                onChange={handleBatchChange}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                            />
                                        </div>
                                    </>
                                )
                            }
                            <div className="formInput" style={{ flexBasis: "65%" }}>
                                <label >
                                    Attachement
                                </label>
                                <Select
                                    value={attchedFileType}
                                    isMulti
                                    name="attched_file_type"
                                    options={options}
                                    onChange={setAttchedFileType}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                />
                            </div>
                            {
                                attchedFileType.map((item, i) => {
                                    return (
                                        <div className="add-new-user-form" style={{ width: "100%", alignItems: "center" }} key={String(i+'i')}>

                                            <div className="formInput" style={{ flexBasis: '40%' }}>
                                                <label >
                                                    {item.label}
                                                </label>
                                                <Dropzone onDrop={acceptedFiles => onDrop(acceptedFiles, item)}>
                                                    {({ getRootProps, getInputProps }) => (
                                                        <section style={{
                                                            minWidth: '100%',
                                                            padding: '1px 10px',
                                                            borderRadius: 4,
                                                            border: '1px solid #42526e',
                                                            boxSizing: 'border-box',
                                                            backgroundColor: '#fff'
                                                        }}>
                                                            <div {...getRootProps({ className: 'dropzone' })}>
                                                                <input {...getInputProps()} />
                                                                <p>Drag 'n' drop some files here, or click to select files</p>
                                                                <em>(Only *.jpeg and *.png images will be accepted)</em>
                                                            </div>
                                                            <div style={{ display: 'flex' }}>
                                                                <aside>
                                                                    {showUploadeFile &&
                                                                        showUploadeFile.map((file, i) => {
                                                                            return (
                                                                                item.value === file.display_name && !files[item.value] &&
                                                                                <div style={thumb} key={i}>
                                                                                    <div style={thumbInner}>
                                                                                        <img
                                                                                            src={(REACT_APP_API_BASEURL).replace("api/", "api") + file.api_path_for_download}
                                                                                            style={img}
                                                                                            alt="not found"
                                                                                        />

                                                                                    </div>
                                                                                </div>

                                                                            )
                                                                        })}
                                                                    {files[item.value] &&

                                                                        files[item.value].file.map(file => (
                                                                            file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg" ?
                                                                                <div style={thumb} key={file.name}>
                                                                                    <div style={thumbInner}>
                                                                                        <img
                                                                                            src={file.preview}
                                                                                            style={img}
                                                                                            // Revoke data uri after image is loaded
                                                                                            onLoad={() => { URL.revokeObjectURL(file.preview) }}
                                                                                            alt="not found"
                                                                                        />

                                                                                        {/* {<HighlightOffTwoToneIcon onClick={removeFiles} />} */}
                                                                                        :
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <ul key={file.name}>
                                                                                    <li>
                                                                                        {file.name} - {file.size} bytes
                                                                                    </li>
                                                                                </ul>

                                                                        ))
                                                                    }

                                                                </aside>

                                                                {Object.keys(files).filter(elem => elem === item.value).length > 0 && item && <HighlightOffTwoToneIcon style={{}} onClick={() => removeFiles(item.value)} />}
                                                            </div>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                            </div>
                                        </div>
                                    )
                                })}
                            {/* <div className="formInput" style={{ flexBasis: "65%" }}>
                                <Dropzone onDrop={onDrop}>
                                    {({ getRootProps, getInputProps }) => (
                                        <section style={{
                                            minWidth: '100%',
                                            padding: '1px 10px',
                                            borderRadius: 4,
                                            border: '1px solid #42526e',
                                            boxSizing: 'border-box',
                                            backgroundColor: '#fff'
                                        }}>
                                            <div {...getRootProps({ className: 'dropzone' })}>
                                                <input {...getInputProps()} />
                                                <p>Drag 'n' drop some files here, or click to select files</p>
                                            </div>

                                            <aside>
                                                <ul>
                                                    {showUploadeFile &&
                                                        showUploadeFile.map((file, i) => {
                                                            return (<li key={i}>
                                                                <div style={thumb}>
                                                                    <div style={thumbInner}>
                                                                        <img
                                                                            src={(REACT_APP_API_BASEURL).replace("api/", "api") + file.api_path_for_download}
                                                                            style={img}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                {file.file_name}
                                                            </li>
                                                            )
                                                        })}
                                                    {files.map((file, i) => (
                                                        <li key={file.name}>
                                                            {file.name} - {file.size} bytes  {<HighlightOffTwoToneIcon onClick={() => removeFiles(i)} />}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </aside>
                                        </section>
                                    )}
                                </Dropzone>
                            </div> */}
                            <div className="formInput" style={{ flexBasis: "65%" }}>
                                <label >
                                    Address
                                </label>

                                <textarea defaultValue={formValues.address} name="address"
                                    onChange={handleChange} rows="3" placeholder="Full Address here"></textarea>

                                {errSatus === true && formValues && formValues.address === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.addressErr}</p>}
                            </div>
                        </div>
                        {loader === true ?
                            <button disabled style={{ backgroundColor: '#808080' }} className="btn btn-submit">{candidateId ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}</button>

                            :
                            <button onClick={submitData} className="btn btn-submit">{candidateId ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}</button>
                        }
                    </form>
                </div>

            </div >
        </div >

    )

}

export default ProfileEdit