import React,{ useEffect, useState } from "react";
import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./resource.scss";
import { DataGrid } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import CONSTANTS from "../../components/constant/constantComponents";
import { useDispatch, useSelector } from "react-redux";
import { getCandidateList, candidatDeleteById, importCandidateFile, candidateUpdateById } from "../../redux/features/resourceSlice";
import { STATUSES } from "../../redux/common-status/constant";
import { TablePagination,Tooltip } from "@mui/material";
import LoaderForm from "../../components/common-function/LoaderForm";
import { toast, ToastContainer } from "react-toastify";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../components/TablePaginationActions";
import AddIcon from '@mui/icons-material/Add';
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import { candidateListOption, roleListOption } from "../../redux/features/optionsSlice";
import { Download } from "@mui/icons-material";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import CustomFilterFn from "../../components/datatable-coulms/customFilter";
import { candidateListColumns } from "../../components/datatable-coulms/tableCoulmns";
const user = GetLocalstorage.userDetail();
const tmsRole = user?.tms_role
const roleWiseFilter = tmsRole && (tmsRole.role_key === 'admin' || tmsRole.role_key === 'super_admin') ? `{"skip":0, "limit":10,"order_by":{"column":"-id"}}` : `{"filter":{"id":{"value":${user.id},"operation": "eq"}},"skip":0,"limit":10,"order_by":{"column":"-id"}}`
const tmsRoleForVerify = tmsRole && (tmsRole.role_key === 'admin' || tmsRole.role_key === 'super_admin')

const CandidateList = () => {

  const { REACT_APP_API_BASEURL } = process.env;
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const { status, error, deleteCandidate, candidateList, import_candidate_file, updateCandidate } = useSelector(state => state.resource)
  const { data: list } = candidateList;
  const { candidateName, candidateEmail, roles_candidate } = useSelector(state => state.dropDownOption)
  const [open, setOpen] = useState(false);
  const [syncLoading, setSyncLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(0);
  const [filterQuery, setFilterQuery] = useState(roleWiseFilter)
  const [pageSize, setPageSize] = useState(10);
  const [limit, setLimit] = useState(10);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: true });
  const [otpApprove, setOtpApprove] = useState(false)
  const [archiveId, setArchiveId] = useState(0)
  const [deleteId, setDeleteId] = useState(null)
  const [archiveOpen, setArchiveOpen] = useState(false)
  const [changeStatus, setChangeStatus] = useState('');

  // file uploading states
  const uploadInputRef = React.useRef(null);

  //error handling
  useEffect(() => {
    if (status === STATUSES.FAILURE && error) {
      toast.error(error.message)
      setSyncLoading(false)
    }
    // eslint-disable-next-line
  }, [error])

  useEffect(() => {
    dispatch(getCandidateList(filterQuery))
    dispatch(roleListOption())
    dispatch(candidateListOption())
    // eslint-disable-next-line
  }, [])

  const handleDelete = (id) => {
    setDeleteId(id)
    setOpen(true)

    setArchiveId(id)
    setArchiveOpen(true)
    if (id.status === true) {
      setChangeStatus("InActive")
    } else {
      setChangeStatus("Active")
    }
  }
  const handleClickArchive = (data) => {
    console.log(data, "data")
    setSyncLoading(true)
    setArchiveOpen(false)
    let addvalues = {
      first_name: data.first_name,
      last_name: data.last_name,
      tms_role: data.tms_role?.id,
      status: !data.status,
      email: data.email,
      password: data.password,
      // id: data.id,
      user: data.user?.id,
      updated_by: GetLocalstorage.userDetail().id
    }
    setSyncLoading(true)
    dispatch(candidateUpdateById({ id: data.id, addvalues: addvalues }))
  }

  if (syncLoading === true && status === `${STATUSES.SUCCESS}_deleteCandidate` && deleteCandidate && deleteCandidate.message) {
    setSyncLoading(false)
    if (deleteCandidate.status === 200) {
      toast.success(deleteCandidate.message)
    } else {
      toast.error(deleteCandidate.message)
    }
    setTimeout(() => {
      dispatch(getCandidateList(roleWiseFilter))
    }, 500);
  }

  if (syncLoading === true && status === `${STATUSES.SUCCESS}_updateCandidate` && updateCandidate && updateCandidate.message) {
    if (updateCandidate.status === 200) {
      toast.success(updateCandidate.message)
      dispatch(getCandidateList(filterQuery))
    } else {
      toast.error(updateCandidate.message)
    }
    setSyncLoading(false)
  }

  const handleClickOpen = (id) => {
    const request = { status: 3 }
    setSyncLoading(true)
    if (open === true) {
      dispatch(candidatDeleteById(id, request))
      setOpen(false)
    }

  }

  const handleClickEdit = (id) => {
    navigate("/edit-profile", { state: { id: id } })

  }

  const approveByAdmin = (data, key) => {
    if (key === "verification") {
      setSyncLoading(true)
      setOtpApprove(false)
      let addvalues = {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        contact_no: data.contact_no,
        password: data.password,
        tms_role: data.tms_role_id,
        updated_by: GetLocalstorage.userDetail().id,
        is_otp_verified: true,
        status: data.status,
        user: data?.user_id
      }
      let id = data.id
      dispatch(candidateUpdateById({ id, addvalues }))
    } else {
      setOtpApprove(true)
    }
  }


  const columns = [
    {
      field: "id",
      headerName: "User Name",
      width: 200,
      type: 'singleSelect',
      valueOptions: candidateName,
      renderCell: (params) => {
        return (
          <div className="cellWithStatus">
            {params.row?.first_name} {params.row?.last_name}
          </div>
        );
      },
    },
    { field: "email", headerName: "Email", width: 300, type: 'singleSelect', valueOptions: candidateEmail },
    { field: "contact_no", headerName: "Contact No.", width: 150 },
    {
      field: "tms_role",
      headerName: "Role",
      width: 150,
      type: 'singleSelect',
      valueOptions: roles_candidate,
      renderCell: (params) => {
        return (
          <div className="cellWithStatus">
            {params?.row?.tms_role && params.row.tms_role.role_name && params.row.tms_role.role_name}
          </div>
        );
      },
    },
  ];

  const actionColumn =[
    {
      field: "action",
      headerName: "Action",
      width: 120,
      hideable: false,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <UcActionComponent
              moduleName="resource"
              rowid={params.row.id}
              addMoreData={params.row}
              editLinkUrl=""
              viewLinkUrl={`view/${params.row.id}`}
              isDelete={true}
              deleteIconName="ArchiveIcon"
              editButton={handleClickEdit}
              viewButton={false}
              additionalAction={params && params.row?.is_otp_verified === false ? [{ id: 1, key: "verifiedByAdmin", title: "Verify By Admin" }] : ""}
              extraButton={params.row?.is_otp_verified === false && tmsRoleForVerify ? () => approveByAdmin(params.row, "verification") : ""}
              deleteButton={() => handleDelete(params.row)} />
          </div>
        );
      },
    },
  ]

  const onFilterChange = (filterModel) => {
    if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
      let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
        filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
          filterModel.items[0].operatorValue === "is" || filterModel.items[0].operatorValue === 'equals' ? 'eq' :
            filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue === 'isAnyOf' ? 'in' : filterModel.items[0].operatorValue
      
      const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":${JSON.stringify(filterModel.items[0].value)},"operation": "${operatorValue}"}},"skip":0, "limit":${limit},"order_by":{"column":"-id"}}`
      setFilterQuery(filterData)
      setCurrentPage(0)
      dispatch(getCandidateList(filterData))
    }
    if (filterModel.items && filterModel.items.length === 0) {
      setCurrentPage(0)
      setPageSize(10)
      setFilterQuery(roleWiseFilter)
      dispatch(getCandidateList(roleWiseFilter))
    }
  }

  //custom pagination
  const handlePagination = (data) => {
    setCurrentPage(data)
    let newFilterData = JSON.parse(filterQuery);
    newFilterData['skip'] = data * pageSize
    newFilterData['limit'] = limit
    dispatch(getCandidateList(JSON.stringify(newFilterData)))
  }

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize.target.value)
    setSyncLoading(false)
    setCurrentPage(0)
    setLimit(newPageSize.target.value)
    let newFilterData = JSON.parse(filterQuery);
    newFilterData['skip'] = 0
    newFilterData['limit'] = newPageSize.target.value
    dispatch(getCandidateList(JSON.stringify(newFilterData)))
  }

  const handleClose = () => {
    setOpen(false)
  }

  function CustomPagination() {
    return (
      <TablePagination
        component="div"
        count={(list && list.rows && list.count) || 0}
        page={currentPage}
        rowsPerPage={pageSize}
        onPageChange={() => setCurrentPage(pageSize)}
        onRowsPerPageChange={handlePageSizeChange}
        ActionsComponent={(subProps) =>
          <TablePaginationActions {...subProps}
            handlePagination={handlePagination}
          />}
      />
    );
  }

  // sorting
  const handleSort = (sort) => {
    if (sort.length) {
      let newFilterData = JSON.parse(filterQuery);
      newFilterData['skip'] = 0;
      newFilterData['limit'] = limit;
      newFilterData['order_by'] = {
        "column": sort[0].sort === "desc" ?
          '-' + sort[0].field : sort[0].field
      }
      setFilterQuery(JSON.stringify(newFilterData))
      dispatch(getCandidateList(JSON.stringify(newFilterData)))
    } else {
      setFilterQuery(`{ "skip": 0, "limit": 10, "order_by": { "column": "-id" } }`)
    }
  }

  // import file code here start
  if (syncLoading === "import_file" && import_candidate_file && import_candidate_file.status) {
    if (import_candidate_file.status === 200) {
      toast.success(import_candidate_file.message)
      dispatch(getCandidateList(filterQuery))
    } else {
      toast.error(import_candidate_file.message)
    }
    setSyncLoading(false)
  }

  const handleImport = (event) => {
    let file = event.target.files[0]
    // let type = file.type
    const formData = new FormData();
    formData.append("file", file);
    const fileType = file.name.split(".")[1];

    if (fileType === "xlsx") {
      setSyncLoading('import_file')
      dispatch(importCandidateFile(formData))
    } else {
      toast.error("You can only import excel file")
    }
  }
  // import file code end

  // download sapmle file code here
  const downloadSampleFile = () => {
    let url = REACT_APP_API_BASEURL + "evaluation/candidate/download/sample/1"
    window.open(url);
  }

  return (
    <div className="resource">
      <Sidebar />
      <div className="resourceContainer">
        <Navbar />
        <ToastContainer />
        <div className="datatable">
          <div className="top-headings">
            <h3>{CONSTANTS.RESOURCE.LIST}</h3>
            <div>
              <Tooltip title="Add New Candidate">
                <Link to="add" className="btn-add-new">
                  <AddIcon />
                </Link>
              </Tooltip>
              <input
                id='fileUpload'
                ref={uploadInputRef}
                type='file'
                multiple
                style={{ display: "none" }}
                onChange={handleImport}
                name="file"
              />
              <Tooltip title="Import File">
                <button
                  onClick={() => uploadInputRef?.current && uploadInputRef.current.click()}
                  className="btn-add-new">Import </button>
              </Tooltip>
              <Tooltip title="Download Sample file">
                <button className="btn-add-new" onClick={downloadSampleFile}><Download /> Sample File </button>
              </Tooltip>
            </div>
          </div>

          {(syncLoading === true || status === STATUSES.LOADING) && <LoaderForm />}
          <DataGrid
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            className="datagrid"
            rows={list?.rows ? list.rows : []}
            columns={columns.concat(CustomFilterFn(candidateListColumns),actionColumn)}
            pageSize={pageSize}
            style={{ fontSize: 16 }}
            onFilterModelChange={onFilterChange}
            filterMode="server"
            components={{
              Pagination: CustomPagination,
            }}
            onSortModelChange={(sort) => handleSort(sort)}
          />
          {error && <p style={{ color: "#96332c", fontSize: 12 }}> {error.message}</p>}
        </div>


        {/* for delete popup */}
        <DeleteDialogModal
          // open={open}
          open={archiveOpen}
          onClose={setArchiveOpen}
          heading={changeStatus}
          paragraph={`Are you sure to ${changeStatus} candidate`}
          handleArchive={handleClickArchive}
          // id={deleteId}
          id={archiveId}
        />
      </div>

    </div>
  )
}

export default CandidateList